import React, {useEffect, useMemo, useState} from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {CalculateRelativeScore, CalculateResponseValues} from "./MCMA_Utils";
import {FormGroup, withWidth} from "@material-ui/core";
import {optionStyles as useStyles, handleCheckboxOptionStyle, handleClassOfWrittenOption} from '../questionTypesStyles';
import {SCREEN_WIDTHS, widthValues} from "../../../utils/common";
import PropTypes from "prop-types";

//Multiple Choice Multiple Answers Type Quiz (has to always have 4 possible answers)
function MCMA(params) {

    const classes = useStyles();
    const isMobile = (params.width === SCREEN_WIDTHS.XS);
    const ques = params.question;

    const options = useMemo(() => {
        let opt = [];
        for (let i=0; i < ques.options.length; i++) {
            let option = ques.options[i];
            opt.push(option.Key);
        }
        return opt;
    }, [ques]);

    const [state, setState] = useState({
        option1: false,
        option2: false,
        option3: false,
        option4: false,
    });

    const {option1, option2, option3, option4} = state;

    //Updates required values for response and relative score
    useEffect(() => {
        if (!isMobile) {
            const maxHeight = Math.max(
                document.getElementById('option1').clientHeight,
                document.getElementById('option2').clientHeight,
                document.getElementById('option3').clientHeight,
                document.getElementById('option4').clientHeight,
            ) + 5;

            document.getElementById('option1').style.height = maxHeight.toString().concat("px");
            document.getElementById('option2').style.height = maxHeight.toString().concat("px");
            document.getElementById('option3').style.height = maxHeight.toString().concat("px");
            document.getElementById('option4').style.height = maxHeight.toString().concat("px");
        }

        let response = CalculateResponseValues(option1, option2, option3, option4, options);
        params.setResponse(response.toString());
        params.setRelativeScore(CalculateRelativeScore(ques, response));

    }, [ques, option1, option2, option3, option4, options, params]);

    const handleChange = (event) => {
        setState({...state, [event.target.name]: event.target.checked});
    };

    const RenderOption = (params) => {
        return (
            <div className={!isMobile && classes.outerDiv}>
                <FormControlLabel id={params.optionId}
                                  className={handleClassOfWrittenOption(params.isOptionOnRight, params.checked)}
                                  label={<p className={classes.quesTitleStyle}>{params.value}</p>}
                                  control={
                                      <Checkbox checked={params.checked}
                                                className={handleCheckboxOptionStyle(params.checked)}
                                                onChange={handleChange} name={params.optionId}/>
                                  }
                />
            </div>
        )
    }

    const CurrentQuestionOptions = () => {
        return (
            <React.Fragment>
                <div className={classes.twoOptionsDiv}>
                    <RenderOption optionId={"option1"} value={options[0]} isOptionOnRight={false} checked={option1}/>
                    <RenderOption optionId={"option2"} value={options[1]} isOptionOnRight={true} checked={option2}/>
                </div>
                <div className={classes.twoOptionsDiv}>
                    <RenderOption optionId={"option3"} value={options[2]} isOptionOnRight={false} checked={option3}/>
                    <RenderOption optionId={"option4"} value={options[3]} isOptionOnRight={true} checked={option4}/>
                </div>
            </React.Fragment>
        )
    }

    return (
        <FormGroup className={ques.titleimage ? "" : classes.OuterOptionsDiv}>
            <CurrentQuestionOptions/>
        </FormGroup>
    )

};

MCMA.propTypes = {
    width: PropTypes.oneOf(widthValues),
}
export default withWidth()(React.memo(MCMA));