import React from "react";
import loadingAnimation from "../../assets/resultLoading.json";
import Lottie from "react-lottie";
import {loadingScoreStyle as useStyles} from './QuizScoreStyle';
import {SCREEN_WIDTHS, widthValues} from "../../utils/common";
import PropTypes from "prop-types";
import {withWidth} from "@material-ui/core";

function LoadingScore(params) {
    const isMobile=(params.width === SCREEN_WIDTHS.XS);
    const classes = useStyles();
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: loadingAnimation,
    };
    return (
        <div className={classes.HeaderStyle}>
            <div className={classes.divStyle} >
                <Lottie options={defaultOptions} isClickToPauseDisabled={true} height={isMobile?"300px":"600px"}/>
                <div className={classes.textStyle}><h4>Creating your score...</h4>
                </div>
            </div>
        </div>
    )
}
LoadingScore.propTypes={
    width: PropTypes.oneOf(widthValues),
}
export default withWidth()((LoadingScore));