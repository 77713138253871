/**
 * This is User Model to store the values
 * @constructor
 */
export function AssessmentModel() {
    this.id = "";
    this.badge = "";
    this.email = "";
    this.userid = "";
    this.endtime = "";
    this.questionattempts = [];
    this.questionweightages = [];
    this.quizmodel = "";
    this.starttime = 0;
    this.status = "";
    this.totalscore = "";
    this.scorerange = "";
}

/**
 * This function is used to create an Assessment model from the json
 * @param json: takes json as input
 * @returns {AssessmentModel}: return AssessmentModel with provided json
 * @constructor
 */
export function AssessmentModelFromJson(json) {
    let assessmentModel = new AssessmentModel();
    assessmentModel.id = json['id'];
    assessmentModel.badge = json['badge'];
    assessmentModel.email = json['email'];
    assessmentModel.userid = json['uid'];
    assessmentModel.endtime = json['endtime'];
    assessmentModel.questionattempts = json['questionattempts'];
    assessmentModel.questionweightages = json['questionweightages'];
    assessmentModel.quizmodel = json['quizmodel'];
    assessmentModel.starttime = json['starttime'];
    assessmentModel.status = json['status'];
    assessmentModel.totalscore = json['totalscore'];
    assessmentModel.scorerange = json['scorerange'];
    return assessmentModel;
}

/**
 * This is Question Model to store the values
 * @constructor
 */
export function QuestionModel() {
    this.id = "";
    this.forages = [];
    this.questiontype = "";
    this.subcategory = "";
    this.timeallowed = "";
    this.title = "";
    this.titleimage = "";
    this.options = {};
}

/**
 * This function is used to create a Question model from the json
 * @param json: takes json as input
 * @returns {QuestionModel}: return QuestionModel with provided json
 * @constructor
 */
export function QuestionModelFromJson(json) {
    let questionModel = new QuestionModel();
    questionModel.id = json['id'];
    questionModel.forages = json['forages'];
    questionModel.questiontype = json['questiontype'];
    questionModel.subcategory = json['subcategory'];
    questionModel.timeallowed = json['timeallowed'];
    questionModel.title = json['title'];
    questionModel.titleimage = json['titleimage'];
    questionModel.options = json['options'];
    return questionModel;
}

/**
 * This is Question Attempt Model to store the values
 * @constructor
 */
export function QuestionAttemptModel() {
    this.questionid = "";
    this.response = "";
    this.relativescore = "";
}

/**
 * Function to create the jsonified version of a questionAttempt
 * @param questionAttempt takes questionAttempt model as a parameter to convert it to json
 * @returns {string} returns jsonified string of the questionAttempt for the requests
 * @constructor
 */
export function QuestionAttemptModelToJson(questionAttempt) {
    return JSON.stringify({
        "questionid": questionAttempt.questionid,
        "response": questionAttempt.response,
        "relativescore": questionAttempt.relativescore,
    });
}

/**
 * This is UserFeedback Model to store the values
 * @constructor
 */
export function UserFeedbackModel() {
    this.assessmentid = "";
    this.rating = 4;
    this.ratingText = "";
}

/**
 * Function to create the jsonified version of a userFeedback
 * @param userFeedback takes userFeedback model as a parameter to convert it to json
 * @returns {string} returns jsonified string of the userFeedback for the requests
 * @constructor
 */
export function UserFeedbackModelToJson(userFeedback) {
    return JSON.stringify({
        "assessmentid": userFeedback.assessmentid,
        "rating": userFeedback.rating,
        "ratingText": userFeedback.ratingText,
    });
}


/**
 * This is Submit Assessment Model to store the values
 * @constructor
 */
export function SubmitAssessmentModel() {
    this.badgetext = "";
    this.badgetitle = "";
    this.badgeurl = "";
    this.totaltime = "0.0";
    this.totalscore = "0.0";
    this.scorerange = "";
}

/**
 * This function is used to create a Question model from the json
 * @param json: takes json as input
 * @returns {SubmitAssessmentModel}: return QuestionModel with provided json
 * @constructor
 */
export function SubmitAssessmentModelFromJson(json) {
    let submitAssessmentModel = new SubmitAssessmentModel();
    submitAssessmentModel.badgetext = json['badgetext'];
    submitAssessmentModel.badgetitle = json['badgetitle'];
    submitAssessmentModel.badgeurl = json['badgeurl'];
    submitAssessmentModel.totaltime = json['totaltime'];
    submitAssessmentModel.totalscore = json['totalscore'];
    submitAssessmentModel.scorerange = json['scorerange'];
    return submitAssessmentModel;
}
