import React from "react";
import Lottie from 'react-lottie';
import quizLoadingAnimation from '../../assets/quizLoading.json';

export const QuizLoadingMessage = (params) => {

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: quizLoadingAnimation,
    };

    //Source - https://blog.wheelhouseit.com/trivia
    const randomMessages = [
        "The very first Apple logo featured Sir Isaac Newton sitting underneath a tree, with an apple about to hit his head",
        "You cannot reverse a Bitcoin transaction, or be forced to pay",
        "On an average work day, a typist’s fingers travel about 12.6 miles",
        "The radio took 38 years to reach an audience of 50 million but the iPod only took 3",
        "If you were to have your picture taken by the very first camera, you’d need to sit still for 8 hours",
        "The word Ubuntu comes from an African word meaning - I am because of you",
        "86% of people try to plug their USB devices upside down",
        "The first alarm clock could only ring at 4 a.m.",
        "Surgeons who grew up playing video games make 37% fewer mistakes",
        "The very first domain name registered was www.symbolics.com, on the 15th March, 1985"
    ];

    return (
        <div className={params.classes.quizHeaderStyle}>
            <div className={params.classes.loadingBottomVectorStyle}>
            {!params.noQuizAvailableForUser ?
                <div className={params.classes.loadingQuizDiv}>
                    <Lottie options={defaultOptions} isClickToPauseDisabled={true} height={"300px"}/>
                    <div className={params.classes.loadingQuizMessageDiv}>
                        <h4>Loading... Might take a minute. Hold ON!</h4>
                        <h5>Fun Fact - {randomMessages[Math.floor(Math.random() * randomMessages.length)]}</h5>
                    </div>

                </div>
                :
                <div>Sorry, there's no quiz for you. Most likely you do not fit the age criteria of 10-18 years.</div>
            }
            </div>
        </div>
    )
}