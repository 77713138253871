import { makeStyles } from '@material-ui/core/styles';
import headerImage from "../../assets/WelcomeScreenTopVector.svg";
import headerImageMob from '../../assets/LoginPhoneVectorTop.svg';
import bottomVector from '../../assets/WelcomeScreenBottomVector.svg';

export const styles = makeStyles(theme =>({

    termsHeaderStyle: {
        backgroundRepeat: "no-repeat",
        [theme.breakpoints.down('sm')]: {
            width: "100%",
            backgroundImage: `url(${headerImageMob})`,
        },
        [theme.breakpoints.up('lg')]: {
            backgroundImage: `url(${headerImage})`,
            backgroundSize: "contain",
            height: "70vh",
        },
        [theme.breakpoints.between('sm', 'md')]: {
            backgroundImage: `url(${headerImage})`,
            backgroundSize: "contain",
            // height: "45vh",
        },
    },
    termsAndConditionsBox: {
        margin: "auto",
        [theme.breakpoints.up('lg')]: {
            padding: "30px 10px",
            width: "77vw",
        },
        [theme.breakpoints.between('sm', 'md')]: {
            padding: "20px 10px",
            width: "80vw",
        },
        [theme.breakpoints.down('sm')]: {
            padding: "20px 10px",
            width: "88%",
        },
    },
    termsAndConditionsBoxContent: {
        [theme.breakpoints.between('sm', 'md')]: {
            width: "76vw",
            margin: "auto", fontSize: "22px"
        },
    },
    TAC_title: {
        textAlign: "center",
        paddingTop: "25px",
        [theme.breakpoints.up('lg')]: {fontSize: "60px"},
        [theme.breakpoints.between('sm', 'md')]: {
            fontSize: "40px",
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: "25px",
        },
    },
    TACs: {
        textAlign: "left",
        '& li':{
            padding: "10px",
            [theme.breakpoints.between('sm', 'md')]: {
                fontSize: "30px",
            },
        }
    },
    termsBottomVectorStyle: {
        backgroundImage: `url(${bottomVector})`,
        width: "100vw",
        [theme.breakpoints.up('lg')]: {
            backgroundSize: "cover",
            minHeight: "100vh",
            backgroundPosition: "bottom",
        },
        [theme.breakpoints.between('sm', 'md')]: {
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "bottom",
        },
        [theme.breakpoints.down('xs')]: {
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "bottom",
        },
    },
}));