import { makeStyles } from '@material-ui/core/styles';
import headerImage from '../../assets/SplashWebVector.svg';
import headerImageMob from '../../assets/SplashPhoneVector.svg';

export const styles = makeStyles(theme => ({
    introOuterDiv:{
        width: "100%",
        textAlign: "center",
        height: "100vh",
    },
    halfCloudOuterDiv: {
        flex: "0 0 8.333333%",
        maxWidth: "8.333333%",
    },
    FRSlogoOuterDiv:{
        flex: "0 0 83.333333%",
        maxWidth: "83.333333%",
        [theme.breakpoints.between('sm', 'md')]: {
            flex: "0 0 70%",
            maxWidth: "70%",
        },
    },
    tabletTopColor:{
        height: "18vh",
        backgroundColor: "#ffbd94",
    },
    backgroundImageStyle: {
        backgroundImage: `url(${headerImage})`,
        backgroundSize: "cover",
        height: "64vh",
        width: "100vw",
        marginLeft: "0",
        display: "flex",
        flexWrap: "wrap",
        [theme.breakpoints.between('sm', 'md')]: {
            height: "30vh",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
        },
        [theme.breakpoints.down('xs')]: {
            backgroundImage: `url(${headerImageMob})`,
            height: "54vh",
            marginTop: "-5vh",
        }
    },
    column: {
        flexBasis: "0", flexGrow: "1", maxWidth: "100%",
    },
    row: {
        display: "flex", flexWrap: "wrap",
    },
    companyLogoStyle: {
        [theme.breakpoints.up('lg')]: { width: "77px", height: "77px", marginTop: "80px",},
        [theme.breakpoints.between('sm', 'md')]: { width: "10vh",marginTop: "5vh",},
        [theme.breakpoints.down('xs')]: {
            width: "65px",
            height: "60px",
            float: "right",
            marginTop: "-3vh",
        },
    },
    halfCloudStyle: {
        [theme.breakpoints.up('lg')]: {
            width: "80px",
            marginTop: "250px",
            marginLeft: "-40px",
        },
        [theme.breakpoints.between('sm', 'md')]: {
            width: "80px",
            marginTop: "10vh",
        },
        [theme.breakpoints.down('xs')]: {
            width: "80px",
            marginTop: "30vh",
            marginLeft: "-15px",
        },
    },
    FRSLogoStyle: {
        [theme.breakpoints.up('lg')]: {width: "25%", marginLeft: "30px",},
        [theme.breakpoints.between('sm', 'md')]: {
            width: "36%",
            position: "absolute",
            left: "35vw",
            top: "5vh",
        },
        [theme.breakpoints.down('xs')]: {
            width: "80%",
            marginLeft: "30px",
            marginTop: "10%",
        },
    },
    ArrowArchAssetStyle: {
        [theme.breakpoints.up('lg')]:{
            height: "120px",
            width: "120px",
            marginLeft: "-90px",
            marginTop: "20px",
        },
        [theme.breakpoints.between('sm', 'md')]:{
            height: "10vw", marginLeft: "-3vh",
        },
    },
    cloudStyleInInfo: {
        [theme.breakpoints.up('lg')]:{
            width: "80px",
            marginTop: "200px",
        },
        [theme.breakpoints.between('sm', 'md')]:{
            width: "100px",
            position: "absolute",
            bottom: "8vh",
            right: "8vw",
        },
        [theme.breakpoints.down('xs')]: {
            width: "80px",
            float: "right",
            marginTop: "-8vh",
        },
    },
    rocketAssetStyle: {
        [theme.breakpoints.up('lg')]: {
            width: "170px",
            marginTop: "170px",
        },
        [theme.breakpoints.between('sm', 'md')]:{
            left: "-3vw",
            width: "150px",
            bottom: "5vh",
            position: "absolute",
        },
        [theme.breakpoints.down('xs')]: {
            width: "90px",marginTop: "-26px",
        },
    },
    rocketAssetOuterDiv: {flex: "0 0 15%", maxWidth: "15%"},
    FRSTitleTextStyle: {
        fontSize: "40px",
        textAlign: "center",
        fontWeight: "600",
        color: "#454545",
        marginBottom: "0",
        marginTop: "-15px",
        [theme.breakpoints.between('sm', 'md')]: {
            fontSize: "56px",color: "#5d5b5b",marginTop: "30px",
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: "26px",color: "#5d5b5b",marginTop: "60px",
        },
    },
    buttonStyle: {
        height: "42px",
        backgroundColor: "#ffa84b",
        width: "60%",
        margin: "3% 4%",
        fontWeight: "bold",
        justifyContent: "center",
        borderRadius: "25px",
        flex: "0 0 41.666667%",
        maxWidth: "41.666667%",
        padding: "2px 2px",
        [theme.breakpoints.between('sm', 'md')]: {width: "90%",padding: "10px 2px", height: "50px",},
    },
    shareWithFriendsBtn: {
        backgroundColor: "#ffdebc",
        float: "right",
        border: "2px solid rgb(255, 222, 188)",
        fontSize: "18px",
        [theme.breakpoints.down('xs')]:{
            fontSize:"10px",
        },
    },
    letsGetGoingBtn: {
        border: "2px solid #ffa84b", fontSize: "18px",
        [theme.breakpoints.down('xs')]: {fontSize: "10px"},
    },
    ParaStyle: {
        [theme.breakpoints.up('lg')]: {
            width: "42%",
            alignItems: "center",
            margin: "auto",
            flex: "0 0 70%",
            maxWidth: "70%",
        },
        [theme.breakpoints.down('xs')]: {marginTop: "40px", marginBottom: "30px",},
        [theme.breakpoints.between('sm', 'md')]: {alignItems: "center", margin: "auto", maxWidth: "75%",},
    },
    frsTextStyle: {
        width: "100%",
        fontFamily: "OpenSans",
        fontSize: "20px",
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
        textAlign: "center",
        color: "#000000",
        margin: "20px auto",
        [theme.breakpoints.up('lg')]: {width: "90%",},
        [theme.breakpoints.between('sm', 'md')]:{marginBottom: "40px", fontSize: "3vw", minHeight: "143px",},
        [theme.breakpoints.down('xs')]:{ width: "85%", fontSize: "16px", minHeight: "143px",},
    },
    FRSInfoRowStyle: {
        width: "100%",
        marginTop: "-105px",
        display: "flex",
        flexWrap: "wrap",
        [theme.breakpoints.down('md')]: {marginLeft: "0",},
    },
    buttonDiv: {
        margin: "auto",
        [theme.breakpoints.up('lg')]: {width: "70%",},
        [theme.breakpoints.between('sm', 'md')]: {width: "90%",},
        [theme.breakpoints.down('xs')]: {width: "90%",},
    },
    mobileTitle:{
        [theme.breakpoints.only('sm')]: {fontSize: "25px", margin: "15px",},
        [theme.breakpoints.only('md')]: {fontSize: "37px", margin: "15px",},
        [theme.breakpoints.down('xs')]: {fontSize: "20px",},
    },
    phoneInputStyle:{
        width: "fit-content", margin: "auto",
        '& .flag-dropdown':{
            backgroundColor: "unset", borderRight: "unset",
        },
        '& .form-control':{
            padding: "23px 14px 23px 58px",
            [theme.breakpoints.down('xs')]:{width: "100%",},
        },
        [theme.breakpoints.down('xs')]:{width: "90%",},
    },
    LoginDataDiv: {width: "50%", margin: "auto",},
    acceptTAC: {
        textAlign: "left",
        [theme.breakpoints.up('sm')]: {
            flexBasis: "0",
            alignItems: "center",
            flexGrow: "1",
            maxWidth: "100%",
            [theme.breakpoints.up('lg')]:{margin: "10px auto",},
            [theme.breakpoints.between('sm', 'md')]:{margin: "20px auto",height: "50px",},
        },
        [theme.breakpoints.down('xs')]: {
            display: "flex", width: "90%", margin: "20px auto",
        },
        '& span':{
            '& span':{
                '& svg':{
                    color: "#ff8300",
                },
            },
        },
    },
    termsAndConditionsLabelStyle:{
        [theme.breakpoints.up('lg')]: {fontSize: "11px"},
        [theme.breakpoints.only('sm')]: {fontSize: "17px"},
        [theme.breakpoints.only('md')]:{fontSize: "23px"},
        [theme.breakpoints.down('xs')]: {fontSize: "12px"},
    },
    otp_verification_style:{
        fontWeight: "600", display: "block",
    },
    timerStyle:{
        [theme.breakpoints.up('lg')]:{padding: "10px 10px"},
        [theme.breakpoints.down('xs')]:{padding: "80px 10px 20px"},
        [theme.breakpoints.between('sm', 'md')]:{padding: "100px 10px 20px"},
    },
    mobileNoStyle:{fontWeight: "600",},
    resend_style:{
        color: "#ff8300"
    },
    not_received_resend_style:{display:"block"},
    otpInput:{
        margin: "auto",
        [theme.breakpoints.down('sm')]:{margin: "10px auto",},
        width: "fit-content",
        '& div':{
            margin: "10px",
            '& input':{
                height: "40px",
                width: "40px !important",
                [theme.breakpoints.down('xs')]:{width: "30px !important",},
                [theme.breakpoints.between('sm','md')]:{height: "50px",width: "50px !important",},
                borderRadius: "10px",
                border: "1px solid #bdb5b5",
                boxShadow: "0px 1px 0px 1px #bdb5b5",
            },
        },
    },
    timerBtnStyle:{
        border: "unset", background: "unset",
    }
}));

// assign classnames to sign in with google button
export const assignStyleToSignInBtn = (checked) => {
    const style = makeStyles(theme=>({
        signBtn: {
            boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.1)",
            justifyContent: "center",
            border: "none",
            borderRadius: "25px",
            [theme.breakpoints.up('lg')]:{
                width: "130px",height: "40px",
                background : checked ? "#fdd4a9" : "lightgray",
            },
            [theme.breakpoints.between('sm', 'md')]:{
                fontSize: "28px",
                width: "25vw",
                height: "60px",
                margin: "10px auto",
                background : checked ? "#fdd4a9" : "lightgray",
                [theme.breakpoints.only('md')]:{margin: "20px auto",},
            },
            [theme.breakpoints.down('xs')]:{
                width: "40%",
                height: "40px",
                background : checked ? "#fdd4a9" : "lightgray",
            },
        },
    }));

    return (style().signBtn);
}