import quizStudentLogo from "../../assets/DashboardSuccessAsset.png";
import React from "react";
import FullCloud from "../../assets/full_cloud.png";
import PropTypes from "prop-types";
import {SCREEN_WIDTHS, widthValues} from "../../utils/common";
import {withWidth} from "@material-ui/core";

const TakeAssessmentButton = (params) => {

    const classes = params.classes;
    const isMobile=(params.width === SCREEN_WIDTHS.XS);
    const isTablet=((params.width === SCREEN_WIDTHS.SM)||(params.width === SCREEN_WIDTHS.MD));

    return (
        <div className={classes.headerVectorStyle}>
            <div>
                <img className={classes.quizStudentLogoStyle} src={quizStudentLogo} alt={"quiz student logo"}/>
                {!isMobile && !isTablet && <img src={FullCloud} alt="Full cloud" className={classes.dashboardFullCloudStyle}/>}
                <button id={"starttest"} className={classes.assessment_btn_style} onClick={params.handleStartQuiz} disabled={!params.newQuizAllowed}>
                    Take a new Assessment
                </button>
            </div>
            {!params.newQuizAllowed &&
                <p className={classes.quizNotAllowedMessageStyle}>
                    <br/>Sorry, you are not allowed to take a new assessment right now.<br/>
                    It seems you already have a <b>Draft</b> attempt in the last 24 hours or a <b>Completed</b> attempt in the last 15 days.<br/>
                    Please check below to see if you can resume a previous attempt.
                </p>
            }
        </div>
    )
}

TakeAssessmentButton.propTypes={
    width: PropTypes.oneOf(widthValues),
}
export default withWidth()((TakeAssessmentButton));