import React from 'react';
import Progress from "../../assets/X - 18 1.png";
import CenterImage from "../../assets/image 17.png";
import StudentLeaderBoard from "../../assets/Student Leaderboard 1.png";
import AppDownloadApple from "../../assets/AppDownloadApple.png";
import AppDownloadAndroid from "../../assets/AppDownloadAndroid.png";
import {DetailedAnalysisStyle} from '../../screens/outcome/QuizScoreStyle';
import {SCREEN_WIDTHS, widthValues} from "../../utils/common";
import PropTypes from "prop-types";
import {withWidth} from "@material-ui/core";

function DetailedAnalysis (params) {
    const classes = DetailedAnalysisStyle();
    const isTablet=((params.width === SCREEN_WIDTHS.SM)||(params.width === SCREEN_WIDTHS.MD));

    return (
        <React.Fragment>

            {/*it displays the images in blur on the back of the download app preview */}
            <div className={classes.blurDiv}>
                {
                    isTablet ?
                        <React.Fragment>
                            <img className={classes.imgStyle} src={Progress} alt={"skill"}/>
                            <img className={classes.centerImgStyle} src={CenterImage} alt={"skill"}/>
                            <img className={classes.imgStyle} src={StudentLeaderBoard} alt={"skill"}/>
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <img className={classes.centerImgStyle} src={CenterImage} alt={"skill"}/>
                            <img className={classes.imgStyle} src={Progress} alt={"skill"}/>
                            <img className={classes.imgStyle} src={StudentLeaderBoard} alt={"skill"}/>
                        </React.Fragment>
                }
            </div>
            {/* it displays the option to download the app or to go back to home screen */}
            <div className={classes.BlurFrontStyle}>

                <p className={classes.paraStyle}>
                    Download our app for details and to start on your Path to Readiness PTR&trade; TODAY.
                </p>

                <div className={classes.appDownloadOuterDiv}>
                    <a href={"https://apps.apple.com/app/id1528019507"} target="_blank" rel="noopener noreferrer">
                        <img className={classes.appDownloadLinkStyle} src={AppDownloadApple} alt={"skill"}/>
                    </a>
                    <a href={"https://play.google.com/store/apps/details?id=com.itinker_app"} target="_blank" rel="noopener noreferrer">
                        <img className={classes.appDownloadLinkStyle} src={AppDownloadAndroid} alt={"skill"}/>
                    </a>
                </div>

                <p className={classes.cashBackParaStyle}>
                    Use the code <b>itinker</b> and get INR 100/- as soon as you join,<br/> and get another INR 500/- when your friends join.
                </p>
            </div>
        </React.Fragment>
    )
};
DetailedAnalysis.propTypes={
    width: PropTypes.oneOf(widthValues),
}
export default withWidth()(React.memo(DetailedAnalysis));