import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Menu from "@material-ui/core/Menu";
import Fade from "@material-ui/core/Fade";
import MenuItem from "@material-ui/core/MenuItem";
import React from "react";
import {styles as useStyles} from "../../screens/dashboard/DashboardStyleSheet";


//For user to choose whether to edit profile or logout
export const ChoiceButtons = (params) => {
    const classes = useStyles();
    return (
        <React.Fragment>
            {/*shows the user name as button to open the menu*/}
            <button className={classes.userNameStyle} onClick={params.handleProfileNameClick}>
                {params.firstName}
                <ExpandMoreIcon className={classes.expandMoreIconStyle} />
            </button>
            {/* menu having edit you details and logout options*/}
            <Menu
                anchorEl={params.anchorEl}
                open={params.open}
                variant={'menu'}
                onClick={params.handleProfileNameClose}
                onClose={params.handleProfileNameClose}
                TransitionComponent={Fade}>
                <MenuItem onClick={params.handleEditDetails} >Edit Your Details</MenuItem>
                <MenuItem onClick={params.logOut} >Logout</MenuItem>
            </Menu>
        </React.Fragment>
    )
}