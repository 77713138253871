import {
    BuildUserQuiz,
    UpdateUserData,
    RetrieveOngoingQuizQuestions, loadImages,
} from "../../services/Database";
import React, {useState, useEffect, useContext} from "react";
import Quiz from '../quiz/Quiz';
import {DashboardHeader} from "../../components/dashboard/DashboardHeader";
import {ChoiceButtons} from "../../components/dashboard/ChoiceButtons";
import EditPersonalDetails from "../../components/dashboard/EditPersonalDetails";
import TakeAssessmentButton from "../../components/dashboard/TakeAssessmentButton";
import {NoPreviousAssessment} from "../../components/dashboard/NoPreviousAssessment";
import PreviousAssessmentsTable from "../../components/dashboard/PreviousAssessmentsTable";
import {QuizLoadingMessage} from "../../components/dashboard/QuizLoadingMessage";
import IntroScreen from "../home/IntroScreen";
import FullCloud from "../../assets/full_cloud.png";
import {styles as useStyles} from "./DashboardStyleSheet";
import PropTypes from "prop-types";
import {SCREEN_WIDTHS, widthValues} from "../../utils/common";
import {withWidth} from "@material-ui/core";
import {Constants} from "../../utils/Constants";
import AuthContext from '../../utils/AuthContext';
import {Analytics, setAnalyticsScreen} from "../../services/Analytics";
import Cookies from "universal-cookie";

function Dashboard(params) {
    // having bool value for, is the current screen size is of phone ('xs') or not
    const isMobile = (params.width === SCREEN_WIDTHS.XS);
    const isTablet = ((params.width === SCREEN_WIDTHS.SM) || (params.width === SCREEN_WIDTHS.MD));
    // having style classes for the component
    const classes = useStyles();
    // the user is a useContext variable having values of display name and email of the user
    //For checking if user is logged-out, and redirect to welcome page accordingly
    let auth = useContext(AuthContext);
    let user = auth.user;
    let userQuizAttempts = auth.userQuizAttempts;

    //Sort reverse chronologically
    userQuizAttempts.sort((a, b) => {
        return b.starttime - a.starttime;
    })

    const [userData, setUserData] = useState(user);
    const [isLoggedOut, setLoggedOut] = useState(false);
    const [moveToQuiz, setMoveToQuiz] = useState(false);
    const [waitToMoveToQuiz, setWaitToMoveToQuiz] = useState(false);
    const [quizQuestions, setQuizQuestions] = useState([]);
    const [noQuizAvailableForUser, setNoQuizAvailableForUser] = useState(false);
    const [newQuizAllowed, setNewQuizAllowed] = useState(true);
    const [editDetails, setEditDetails] = useState(!user.frsuser);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    //Change values according to the user information
    useEffect(() => {
        setAnalyticsScreen(Constants.screen_dashboard).then()
        if (user) {
            //Check if a quiz attempt already exists in the last 24 hours, in which case new Quiz cannot be started
            //Also, if any of the quiz attempts completed in the last 15 days, new attempts not allowed
            userQuizAttempts.forEach(attempt => {
                if (attempt.status === 'Draft' && new Date().getTime() - new Date(parseInt(attempt.starttime)) < 86400000) {
                    setNewQuizAllowed(false);
                } else if (attempt.status === 'Completed' && new Date().getTime() - new Date(parseInt(attempt.starttime)) < 1296000000) {
                    setNewQuizAllowed(false);
                }
            });
        }
    }, [user, userQuizAttempts])

    //Switches to Edit mode in the UI
    const handleEditDetails = () => {
        Analytics(Constants.screen_dashboard, Constants.track_go_to_edit_profile_button, Constants.track_click).then()
        setEditDetails(true);
    }

    //Handles the activities to trigger start quiz
    const handleStartQuiz = async () => {
        Analytics(Constants.screen_dashboard, Constants.track_take_new_assessment_button, Constants.track_click).then();
        // if the email is not having in the data, then popup appears for updating email else load quiz
        setWaitToMoveToQuiz(true);
        let questions = await BuildUserQuiz(userData);
        if (questions === []) {
            setNoQuizAvailableForUser(true);
            return;
        }
        // load images of the first 10 questions
        let endIndex = 10;
        if (questions[1].length < endIndex) endIndex = questions[1].length;
        let q = await loadImages(questions[1], 0, endIndex);
        setQuizQuestions([questions[0], q]);
        setMoveToQuiz(true);
    }

    //Handles the submit event for the user
    const handleSubmit = async (e, firstName, lastName, email, dob, refCode) => {
        Analytics(Constants.screen_editProfile, Constants.track_save_editProfile, Constants.track_click).then()
        //Prevents this from refreshing the page
        e.preventDefault();
        let updatedUser = userData;
        updatedUser.firstname = firstName;
        updatedUser.lastname = lastName;
        updatedUser.email = email;
        updatedUser.dob = dob;
        updatedUser.frsrefcode = refCode;
        updatedUser.frsuser = true;
        updatedUser.lastmodifiedtimestamp = new Date().getTime().toString()
        let dbUserData = await UpdateUserData(updatedUser);
        setUserData(dbUserData);
        auth.setUser(dbUserData);
        if (dbUserData.frsrefcode !== undefined && dbUserData.frsrefcode !== "") {
            auth.setIsReferralEditable(false);
        }
        setEditDetails(false);
    };

    // handles the submit event of the not having email popup
// function which handles logout functioning when user clicks on logout button
    const logOut = () => {
        Analytics(Constants.screen_dashboard, Constants.track_logout, Constants.track_click).then()
        let cookies = new Cookies();
        cookies.remove(Constants.auth_token, {path: "/", secure: Constants.secureCookies, domain: Constants.domain});
        auth.setUser(null);
        auth.setUserQuizAttempts(null);
        auth.setIsReferralEditable(false);
        setLoggedOut(true);
    }

    // To handle the situation when user wants to resume a quiz
    const handleResumeQuiz = async (attempt) => {
        Analytics(Constants.screen_dashboard, Constants.track_resume_quiz, Constants.track_click).then()
        setWaitToMoveToQuiz(true);
        let questions = await RetrieveOngoingQuizQuestions(attempt);
        if (questions == null) {
            setNoQuizAvailableForUser(true);
            return;
        }
        // load images of the first 10 questions or total question if number of questions are less than 10
        let endIndex = 10;
        if (questions[1].length < endIndex) endIndex = questions[1].length;
        let q = await loadImages(questions[1], 0, endIndex);
        setQuizQuestions([questions[0], q]);
        setMoveToQuiz(true);
    }

    //handles when the user click on the profile name on dashboard
    const handleProfileNameClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    //handles when the user wants to close the menu opened
    const handleProfileNameClose = () => {
        setAnchorEl(null);
    };

    const RenderToDashBoard = () => {
        return (
            <React.Fragment>
                <div className={classes.dashboardBottomVectorStyle}>
                    <div className={classes.assessmentTableStyle}>
                        {!isMobile && !isTablet &&
                        <img src={FullCloud} alt="Full cloud" className={classes.dashboardFullCloudStyle2}/>}

                        {/*displays the score card of the previously attempted quiz if any or message having information that no quiz is attempted by the user */}
                        {userQuizAttempts.length === 0 ?
                            <NoPreviousAssessment pTagStyle={classes.pTagStyle} noRecordStyle={classes.noRecordStyle}/>
                            :
                            <PreviousAssessmentsTable userQuizAttempts={userQuizAttempts} classes={classes}
                                                      handleResumeQuiz={handleResumeQuiz}
                                                      newQuizAllowed={newQuizAllowed}/>
                        }
                    </div>
                </div>
            </React.Fragment>
        )
    }


    //UI - if user is logged in then render the content else redirect the user to Welcome page
    if (isLoggedOut)
        return <IntroScreen/>
    else if (moveToQuiz)
        return <Quiz questions={quizQuestions}/>
    else {
        return (
            <div className={classes.outerDiv}>

                {/* displays the edit you details page if true or else the dashboard of the user */}
                {!waitToMoveToQuiz ?
                    editDetails ?
                        <EditPersonalDetails firstName={userData.firstname} handleSubmit={handleSubmit}
                                             logOut={logOut}/>
                        :
                        <div>
                            <DashboardHeader firstName={userData.firstname} profile_style={classes.profile_style}/>
                            <ChoiceButtons handleProfileNameClick={handleProfileNameClick}
                                           firstName={userData.firstname}
                                           anchorEl={anchorEl} open={open}
                                           handleProfileNameClose={handleProfileNameClose}
                                           handleEditDetails={handleEditDetails} logOut={logOut}/>
                            <TakeAssessmentButton classes={classes} handleStartQuiz={handleStartQuiz}
                                                  newQuizAllowed={newQuizAllowed}/>
                            <RenderToDashBoard/>
                        </div>
                    :
                    <QuizLoadingMessage noQuizAvailableForUser={noQuizAvailableForUser} classes={classes}/>
                }
            </div>
        )
    }
}

Dashboard.propTypes = {
    width: PropTypes.oneOf(widthValues),
}
export default withWidth()((Dashboard));
