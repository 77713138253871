
//Calculates the relative score of the chosen option
export const CalculateRelativeScore = (ques, selectedOption) => {
    let allValues = [];
    let selectedValue = 0;
    for (let i=0; i < ques.options.length; i++) {
        let option = ques.options[i];
        let key = option.Key;
        let value = option.Value;
        if (key === selectedOption) {
            selectedValue = parseFloat(value.toString())
        }
        allValues.push(parseFloat(value.toString()));
    }
    let max = allValues.reduce(function (a, b) {
        return Math.max(a, b);
    });
    max = max === 0 ? 1 : max;
    return (selectedValue / max).toFixed(2).toString();
}