export const Constants = ({
    //API CONSTANTS
    sendOTP: "/sendloginotp",
    login: "/login",
    checkAuth: '/checkauth',
    addUser: '/adduser',
    getUserData: '/getuserdata',
    updateUserData: '/updateuser',

    buildAssessment: '/builduserassessment',
    updatesAssessmentAttempt: '/updateassessmentattempt',
    submitAssessment: '/submituserassessment',
    addFeedback: '/adduserfeedback',
    listAssessments: '/getuserassessments',
    getOngoingAssessmentQues: '/getongoingassessmentquestions',
    sendCertificateEmail: '/sendcertificateemail',


    login_server: process.env.NODE_ENV !== 'production' ? 'https://logindev.itinkerserver.com' : 'https://login.itinkerserver.com',
    otp_token_key: process.env.NODE_ENV !== 'production' ? 'HUfHJB12nmgir99i86jsnn002018' : 'JUDBF84542jkjbdfHIEFn4jefbh845nH',
    frs_data_server: process.env.NODE_ENV !== 'production' ? 'https://frsdev.itinkerserver.com' : 'https://frs.itinkerserver.com',

    //
    auth_token: "auth_token",
    domain: process.env.NODE_ENV !== 'production' ? "localhost" : ".itinker.io",
    secureCookies: process.env.NODE_ENV === 'production',
    //SCREEN NAMES used for analytics
    popup_promotional: "promotional",
    screen_home: "home",
    screen_login: "login",
    screen_dashboard: "dashboard",
    screen_editProfile: "edit_profile",
    screen_quiz: "quiz",
    screen_feedback: "feedback",
    screen_result: "result",

    //Constants for analytics
    userEvent: "user_event",
    track_send_otp: "send_otp",
    track_click: "click",
    track_login_button: "login_button",
    track_take_new_assessment_button: "take_assessment_button",
    track_go_to_edit_profile_button: "go_to_edit_profile_button",
    track_logout: "logout",
    track_resume_quiz: "resume_quiz",
    track_save_editProfile: "save_edit_profile",
    track_goToNextQuestion: "go_to_next_question",
    track_submit_quiz: "submit_quiz",
    track_submit_feedback: "submit_feedback",
    track_popup: "popup",
    track_close: "close",
    track_view: "view",

});