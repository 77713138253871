import {makeStyles} from "@material-ui/core";
import {createMuiTheme} from "@material-ui/core/styles";

// styles for all types of options
export const optionStyles = makeStyles(theme => ({
    outerDiv: {
        [theme.breakpoints.up('lg')]:{flex: "0 0 50%", maxWidth: "50%"},
        [theme.breakpoints.between('sm','md')]:{minWidth: "50%", maxWidth: "90%", margin: "auto"},
    },
    singleOptionStyle: {
        borderRadius: "15px",
        boxShadow: "0 2px 3px 0 rgba(0, 0, 0, 0.15)",
        backgroundColor: "white",
        [theme.breakpoints.up('lg')]:{
            minWidth: "250px",
            margin: "10px auto",
            padding: "10px",
            width: "24vw",
            fontWeight: "bold",
            border: "none",
            flexGrow: "0",
            justifyContent: "center",
            textAlign: "center",
        },
        [theme.breakpoints.down('md')]:{
            padding: "12px 9.5px 9.5px 9.5px",
            margin: "10px auto",width: "100%",
        }
    },
    quesTitleStyle: {
        fontFamily: "'Open Sans', sans-serif",
        fontWeight: "600",
        justifyContent: "center",
        textAlign: "center",
        alignItems: "center",
        margin: "auto",
    },
    row: {display: "flex", flexWrap: "wrap"},
    twoOptionsDiv: {
        [theme.breakpoints.up('lg')]:{display: "flex", flexWrap: "wrap"},
    },
    OuterOptionsDiv: {
        [theme.breakpoints.up('lg')]:{width: "80%", margin: "auto"},
    },
    radioButtonStyle: {
        width: "18px",
        height: "18px",
        flexGrow: "0",
        padding: "5px 4px 4px 4px",
        margin: "10px !important",
    },
    singleOptionStyleForImage: {
        flexGrow: "0",
        padding: "9px 12px 9px 7px",
        borderRadius: "15px",
        [theme.breakpoints.down('sm')]:{width: "260px", margin: "auto"},
    },
    imageOptionsOuterDiv:{
        [theme.breakpoints.up('lg')]:{ width: "80%", margin: "auto",},
    },
    portraitOuterDiv:{
        [theme.breakpoints.up('lg')]:{ display: "block",},
    }
}));

/// handles to assignment of classes to single option
export const handleClassOfWrittenOption = (isOptionOnRight, optionValue) => {
    const style = makeStyles(theme=> ({
        OptionOnRightStyle:{
            backgroundColor: optionValue ? "#ffdebc" : "",
            [theme.breakpoints.up('lg')]:{
                float: isOptionOnRight ? "right" : "left",
            },
        },
        optionStyle:{
            backgroundColor: optionValue ? "#ffdebc" : "",
        },
    }));
    return (`${optionStyles().singleOptionStyle} ${style().optionStyle}`)
}

// handles to assignment of classes to checkbox in the options part
export const handleCheckboxOptionStyle = (optionState) => {
    const style = makeStyles({
        optionSelected: {
            color: "#ffdebc !important",
            backgroundColor: "#ffb05e",
            margin: "4px",
            border: "2px solid #ffb05e",
            borderRadius: "6px",
        },
        optionNotSelected: {
            margin: "10px",
            marginRight: "2px",
            color: "#d0cbc9",
            borderRadius: "6px"
        },
        checkbox_style: {
            width: "18px",
            height: "18px",
            flexGrow: "0",
            margin: "10px 10px 10px 10px",
        },
    })
    const classes = style();

    if (optionState) return `${classes.checkbox_style} ${classes.optionSelected}`
    else return `${classes.checkbox_style} ${classes.optionNotSelected}`
}

// handles the assignment of classes to radio button in options
export const handleRadioButtonOptionStyle = (CheckedValue, value) => {
    const style = makeStyles({
        radioButtonStyle: {
            width: "18px",
            height: "18px",
            flexGrow: "0",
            padding: "5px 4px 4px 4px",
            margin: "10px !important",
            color: CheckedValue === value ? "#ff8300 !important" : "",
        },

    })
    const classes = style();

    return `${classes.radioButtonStyle}`
}

// type in the answer theme
export const TIATheme = createMuiTheme({
    overrides: {
        // Style sheet name ⚛️
        MuiOutlinedInput: {
            root:{
                position: "relative",
                borderRadius: "5px",
                width: "80%",
                margin: "30px auto auto",
            },
        },
        MuiFormControlLabel: {
            // Name of the rule
            label: {
                // Some CSS
                textAlign: "center",
                alignItems: "center",
                margin: "auto"
            },
        },
        MuiFormControl: {
            root: {
                display: "contents",
            }
        },
        MuiFormLabel: {
            root: {
                height: "90%",
            }
        }
    },
});

// type in the answer with multiple values web theme
export const TIAMV_theme = createMuiTheme({
    overrides: {
        // Style sheet name ⚛️
        MuiAutocomplete: {
            root:{
                width: "80%",
                margin: "30px auto auto",
            },
            inputRoot:{
                marginTop: "0px !important",paddingRight: "30px !important",
            },
        },
        MuiFormControlLabel: {
            // Name of the rule
            label: {
                // Some CSS
                textAlign: "center",
                alignItems: "center",
                margin: "auto"
            },
        },
        MuiFormControl: {
            root: {
                display : "contents",
            }
        },
        MuiFormLabel: {
            root: {
                height: "100%",
                '&$focused':{
                    width:"max-content"
                },
            },
            focused: {}
        },
        MuiChip: {
            root: {
                border: "2px solid orange",
                backgroundColor: "white",
            },
            deleteIcon: {
                color: "orange",
            }
        },
        MuiInputLabel : {
            formControl: {
                marginBottom: "0",
                width: "100%",
                top: "unset",
                left: "unset",
                transform: "none",
                height: "max-content",
                position: "unset",
            }
        },
    },
});

// type in the answer with multiple values mobile theme
export const TIAMV_themeMob = createMuiTheme({
    overrides: {
        // Style sheet name ⚛️
        MuiFormControlLabel: {
            // Name of the rule
            label: {
                // Some CSS
                textAlign: "center",
                alignItems: "center",
                margin: "auto"
            },
        },
        MuiFormControl: {
            root: {
                display : "contents",
            }
        },
        MuiFormLabel: {
            root: {
                height: "100%",
                '&$focused':{
                    width:"max-content"
                },
            },
            focused: {}
        },
        MuiChip: {
            root: {
                border: "2px solid orange",
                backgroundColor: "white",
            },
            deleteIcon: {
                color: "orange",
            }
        },
        MuiInputLabel : {
            formControl: {
                marginBottom: "0",
                width: "100%",
                top: "unset",
                left: "unset",
                transform: "none",
                height: "max-content",
                position: "unset",
            }
        },
        MuiAutocomplete:{
            inputRoot:{
                marginTop: "0px !important",paddingRight: "30px !important",
            },
        },
    },
});

export const handleRadioButtonWithImageOptionStyle = (SelectedValue, isOptionOnRight, isPortrait, quesTitleHasImage) => {
    const style = makeStyles(theme=>({
        radioButtonStyle: {
            width: "18px",
            height: "18px",
            flexGrow: "0",
            padding: "5px 4px 4px 4px",
            margin: "10px !important",
            color: SelectedValue ? "#ff8300 !important" : ""
        },
        OptionImageStyle: {
            height: isPortrait ? quesTitleHasImage ? "200px" : "300px" : "150px",
            margin: "0 0 0 11px",
            borderRadius: "15px",
            backgroundColor: "#c4c4c4",
            border: SelectedValue ? "solid 2.5px rgba(255, 131, 0, 0.5)": "",
            width: "180px",
            [theme.breakpoints.up('lg')]: {
                width: isPortrait ? quesTitleHasImage ? "180px" : "90%" : "180px",
            },
        },
        optionStyle:{
            height: isPortrait ? quesTitleHasImage ? "230px" : "unset" : "180px",
            [theme.breakpoints.up('lg')]: {
                width: isPortrait ? quesTitleHasImage ? "unset" : "24%" : "unset",
            },
            [theme.breakpoints.up('sm')]:{
                float: isOptionOnRight ? "right": "left",
            },
        },
    }));
    const classes = style();
    return {
        radioBtn: `${classes.radioButtonStyle}`,
        imageLabel: `${classes.OptionImageStyle}`,
        optionCls: `${optionStyles().singleOptionStyleForImage} ${classes.optionStyle}`
    };
}


export const handleCheckBoxWithImageOptionStyle = (isChecked, isOptionOnRight, isPortrait, quesTitleHasImage) => {

    const style = makeStyles(theme=>({
        checkbox_style: {
            width: "18px",
            height: "18px",
            flexGrow: "0",
            color: isChecked ? "#ffdebc !important" : "#d0cbc9 !important",
            backgroundColor: isChecked ? "#ffb05e" : "",
            border: isChecked ? "2px solid #ffb05e" : "",
            borderRadius: "6px",
            margin: "2px 4px 4px 4px"
        },
        OptionImageStyle: {
            height: "150px",
            margin: "0 0 0 11px",
            borderRadius: "15px",
            backgroundColor: "#c4c4c4",
            border: isChecked ? "solid 2.5px rgba(255, 131, 0, 0.5)": "",
            width: "180px",
            [theme.breakpoints.up('lg')]: {
                width: isPortrait ? quesTitleHasImage ? "180px" : "90%" : "180px",
            },
        },
        optionStyle:{
            height: isPortrait ? quesTitleHasImage ? "230px" : "unset" : "180px",
            [theme.breakpoints.up('lg')]: {
                width: isPortrait ? quesTitleHasImage ? "unset" : "24%" : "unset",
            },
            [theme.breakpoints.up('sm')]:{
                float: isOptionOnRight ? "right": "left",
            },
        }
    }));
    const classes = style();
    return {
        checkBox: `${classes.checkbox_style}`,
        imageLabel: `${classes.OptionImageStyle}`,
        optionCls: `${classes.optionStyle} ${optionStyles().singleOptionStyleForImage}`
    };
}