import {makeStyles} from '@material-ui/core/styles';
import headerImage from '../../assets/WelcomeScreenTopVector.svg';
import quizHeader from '../../assets/WelcomeScreenTopVector.svg';
import bottomVectorMob from '../../assets/LoginPhoneVectorBottom.svg';
import bottomVector from '../../assets/WelcomeScreenBottomVector.svg';
import editHeaderImage from "../../assets/AdditionalDetailsPhonePage.svg";
import editBottomImage from "../../assets/AdditionalDetailsWebPage.svg";

export const editDetailsStyles = makeStyles(theme => ({
    AdditionalDetailsBackground: {
        backgroundImage: "linear-gradient(to bottom, #fbb888 , #fd9172)",
        width: "100%",
        margin: "0",
        display: "flex",
        flexWrap: "wrap",
        minHeight: "100vh",
    },
    TopLeftVectorStyle: {
        backgroundRepeat: "no-repeat",
        [theme.breakpoints.up('lg')]:{
            backgroundImage: `url(${editBottomImage})`,
            height: "100%",
            flex: "0 0 50%",
            maxWidth: "50%",
            backgroundSize: "contain",
        },
        [theme.breakpoints.down('sm')]:{
            backgroundImage: `url(${editHeaderImage})`,width: "104%",
            backgroundSize: "contain",
        },
        [theme.breakpoints.between('sm', 'md')]:{backgroundImage: `url(${editBottomImage})`,width:"100vw",},
    },
    additionalFullCloudStyle: {
        height: "50px",
        [theme.breakpoints.down('xs')]:{
            width: "100px",
            float: "right",
            marginTop: "10%",
        },
    },
    additionalArchArrowStyle:{
        height: "100px", marginTop: "4%", float: "right",
    },
    InfoStyle: {
        textAlign: "left",
        flex: "0 0 83.333333%",
        maxWidth: "83.333333%",
        [theme.breakpoints.up('lg')]:{margin: "20% auto",},
        [theme.breakpoints.down('xs')]:{margin: "30% auto",},
        [theme.breakpoints.between('sm','md')]:{margin: "24vh auto auto",},
    },
    HiTextStyle: {
        color: "white",
        fontSize: "30px",
        fontWeight: "bold",
    },
    GreetingsUserStyle: {
        fontSize: "23px",
    },
    text_style: {
        fontFamily: "'Open Sans', sans-serif",
        fontSize: "16.5px",
        color: "#000000",
        [theme.breakpoints.between('sm','md')]:{width:"90vw", fontSize: "21px",},
    },
    rocketAssetAdditionalDetailsStyle: {
        height: "170px", float: "left",
    },
    btn_style: {
        borderRadius: "20px",
        border: "1px solid #fd9675",
        boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.15)",
        backgroundColor: "#fd9675",
        [theme.breakpoints.up('lg')]: {
            width: "150px",
            height: "35px",
            padding: "4px 35px",
        },
        [theme.breakpoints.down('xs')]: {
            height: "45px",
            width: "100px",
            margin: "10px",
        },
        [theme.breakpoints.between('sm','md')]: {
            height: "45px",
            width: "25vw",
            margin: "10px",
        },
    },
    CloudAlignmentStyle: {
        height: "50px", float: "right", marginTop: "10%",
    },
    filledInputStyle: {backgroundColor: "#e9f4ff !important", borderRadius: "10px !important"},
    filledInputStyleDob: {fontSize: "15px"},
    inputLabelStyle: {paddingTop: "12px", paddingLeft: "15px", fontSize: "16px"},
    RocketAdditionalStyle: {
        width: "150px",
        position: "absolute",
        right: "8vw",
        bottom: "0vh",
    },
    ArrowArchAssetAdditionalStyle: {width: "10%", height: "10%", marginTop: "3%",flexBasis: "0", flexGrow: "1", maxWidth: "100%",},
    column: {flexBasis: "0", flexGrow: "1", maxWidth: "100%",},
    submitBtnStyle: {
        borderRadius: "20px",
        border: "none",
        backgroundColor: "#fdd4a9",
        [theme.breakpoints.down('xs')]: {
            height: "45px",
            width: "100px",
            margin: "10px",
        },
        [theme.breakpoints.between('sm','md')]: {
            height: "45px",
            width: "25vw",
            margin: "10px",
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: "15px",marginTop: "30px",width: "125px",
            height: "35px",
            padding: "4px 34px",
        },
    },
    infoInputStyle: {
        margin: "auto", width: "40%",
        [theme.breakpoints.down('md')]:{alignItems: "center", width: "80%",},
    },
    formOuterStyle: {
        borderRadius: "25px",
        boxShadow: "-10px 7.5px 8.5px 0 rgba(253, 148, 115, 0.5)",
        backgroundColor: "#ffffff",
        [theme.breakpoints.up('lg')]:{
            width: "375px",
            marginTop: "4vh",
            padding: "45px 15px 6vh 15px",
            flex: "0 0 75%",
            maxWidth: "75%",
        },
        [theme.breakpoints.down('sm')]:{
            width: "85%",
            margin: "auto",
            padding: "20px",
        },
        [theme.breakpoints.between('sm', 'md')]:{
            width: "60%",
            margin: "auto",
            padding: "20px",
        },
    },
    FormOuterDiv: {
        [theme.breakpoints.up('lg')]: {flex: "0 0 50%", maxWidth: "50%",},
        [theme.breakpoints.down('xs')]: {width: "100%", margin: "0"},
        [theme.breakpoints.between('sm', 'md')]: {width: "100%", margin: "0"},
    },
    formStyle: {
        width: "100%",
        [theme.breakpoints.up('lg')]: {marginTop: "-40px",},
        [theme.breakpoints.down('xs')]: {marginTop: "-40px",},
    },
    additionalFormFullCloudStyle:{
        width: "100px", float: "right",
    },
    additionalFullCloudLeftStyle:{
        width: "100px", float: "left",marginTop: "-10vh",marginLeft:"10px",
    },
    row: {display: "flex", flexWrap: "wrap"},
    rocketAssetAdditionalDetailsStyleMob: {
        [theme.breakpoints.up('lg')]:{
            height: "110px", float: "left", marginLeft: "-20px",
        },
        [theme.breakpoints.down('xs')]:{
            height: "110px", float: "left", marginLeft: "-20px",
        },
        [theme.breakpoints.between('sm', 'md')]:{
            height: "15vw",
            marginLeft: "-20vw",
            marginRight: "5vw",
        },
    },
}));


export const styles = makeStyles(theme => ({
    outerDiv:{
        textAlign: "center", height: "100vh",width: "100%",
    },
    profile_style: {
        borderRadius: "80px",
        top: "5%",
        float: "right",
        width: "55px",
        margin: "10px 26px 11.7px -84px",
        boxShadow: "2px 2px 11.5px 0 rgba(0, 0, 0, 0.25)",
        [theme.breakpoints.between('sm','md')]:{
            width: "65px",
        }
    },
    userNameStyle: {
        border: "unset",
        backgroundColor: "unset",
        marginTop: "63px",
        fontSize: "12px",
        fontWeight: "normal",
        textTransform: "capitalize",
        marginBottom: "30px",
        display: "inline",
        float: "right",
        height: "13.5px",
        width: "111px",
        textAlign: "center",
        [theme.breakpoints.between('sm','md')]:{
            marginTop: "73px",
        },
        "&:focus":{
            outline: "unset",
        }
    },
    expandMoreIconStyle:{
        marginTop: "-10px",
        display: "block",
        width: "100%",
    },
    headerVectorStyle: {
        backgroundImage: `url(${headerImage})`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        [theme.breakpoints.down('xs')]:{
            width: "100%",
        },
        [theme.breakpoints.between('sm','md')]:{
            backgroundSize: "cover",width: "100%",height: "45vh",
        },
        [theme.breakpoints.up('lg')]:{height: "45vh",},
    },
    quizStudentLogoStyle: {
        [theme.breakpoints.up('lg')]:{
            marginLeft: "41%",
            width: "233px",
            padding: "20px 15px 5px 15px",
            alignItems: "center",
            display: "flex",
        },
        [theme.breakpoints.down('xs')]:{
            marginBottom: "10px",marginTop: "-10vh",
            width: "80vw",
            padding: "20px 15px 5px 15px",
        },
        [theme.breakpoints.between('sm','md')]:{
            marginLeft: "20vw",width: "40vw",marginBottom: "10px",
        },
    },
    dashboardFullCloudStyle: {
        width: "100px",
        height: "50px",
        position: "absolute",
        left: "20%",
        top: "25%",
    },
    assessment_btn_style: {
        height: "35px",
        justifyContent: "center",
        width: "300px", borderRadius: "12px", backgroundColor: "#FFBD94 !important",
        boxShadow: "2px 2px 11.5px 0 rgba(0, 0, 0, 0.25)",
        border: "none",
        [theme.breakpoints.between('sm','md')]:{marginLeft: "6vw", width: "40vw",},
    },
    quizNotAllowedMessageStyle: {
        [theme.breakpoints.down('xs')]:{width: "100vw", padding: "10px",fontSize: "12px",},
        [theme.breakpoints.between('sm','md')]:{fontSize: "3vw",marginTop: "5vh"},
    },
    quizHeaderStyle: {
        backgroundImage: `url(${quizHeader})`,
        backgroundSize: "contain",
        height: "60vh",
        backgroundRepeat: "no-repeat",
    },
    loadingQuizDiv: {
        height: "100vh",
        display: "flex",
        flexDirection: "Column",
        justifyContent: "center",
        alignItems: "center"
    },
    loadingQuizMessageDiv: {padding: "10px"},
    dashboardFullCloudStyle2: {
        width: "100px",
        height: "50px",
        position: "absolute",
        right: "5%",
    },
    assessmentTableStyle: {
        [theme.breakpoints.down('xs')]:{
            width: "96vw",margin: "auto",
        },
        [theme.breakpoints.between('sm', 'md')]:{
            top: "60vh",
            width: "90vw",
            position: "absolute",
            right: "5vw",
        },
        [theme.breakpoints.up('lg')]:{
            width: "90vw",
            position: "absolute",
            top: "55vh",
            right: "5vw",
        },
    },
    dashboardBottomVectorStyle: {
        backgroundSize: "cover",
        [theme.breakpoints.up('lg')]:{
            backgroundImage: `url(${bottomVector})`,
            marginTop: "-41vh",
            height: "96vh",
        },
        [theme.breakpoints.down('xs')]:{
            backgroundImage: `url(${bottomVectorMob})`,
            backgroundPosition: "bottom",
            minHeight: "39vh",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
        },
        [theme.breakpoints.between('sm', 'md')]:{
            backgroundImage: `url(${bottomVectorMob})`,
            backgroundPosition: "bottom",
            height: "55vh",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
        },
    },
    loadingBottomVectorStyle: {
        [theme.breakpoints.up('lg')]:{
            backgroundImage: `url(${bottomVector})`,
            backgroundPosition: "bottom",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
        },
        [theme.breakpoints.down('xs')]:{
            backgroundImage: `url(${bottomVectorMob})`,
            backgroundPosition: "bottom",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
        },
        [theme.breakpoints.between('sm', 'md')]:{
            backgroundImage: `url(${bottomVectorMob})`,
            backgroundPosition: "bottom",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
        },
    },
    pTagStyle: {
        [theme.breakpoints.down('xs')]:{textAlign: "center", padding: "25% 5%",},
        [theme.breakpoints.between('sm','md')]:{textAlign: "center", padding: "5vh 0px",},
    },
    noRecordStyle: {
        boxShadow: "2px 2px 2px 0 rgba(0, 0, 0, 0.1), -2px -2px 2px 0 rgba(0, 0, 0, 0.05)",
        backgroundColor: "#f5faff",
        borderRadius: "20px",
        [theme.breakpoints.up('lg')]:{
            marginLeft: "20px",
            marginRight: "20px",
            height: "189.5px",
            margin: "37.5px 0 0",
            padding: "74px 284px 73.5px 284.5px",
            maxHeight: 300,
            maxWidth: "97%",
        },
        [theme.breakpoints.down('xs')]:{
            padding: "0px",
            maxWidth: "90%",
            borderRadius: "20px",
            margin: "auto",marginTop: "20px",
        },
    },
    tableStyle: {
        maxHeight: "300px",
        borderRadius: "20px",
        backgroundColor: "#f5faff",
        [theme.breakpoints.down('xs')]: {
            maxWidth: "95%",
            margin: "15px 10px",
            padding: "10px",
        },
        [theme.breakpoints.up('lg')]:{
            maxWidth: "97%",
            margin: "20px auto",
            padding: "10px",
        },
        [theme.breakpoints.between('sm', 'md')]:{padding: "30px 10px",},
    },
    previousAssessmentPara:{
        [theme.breakpoints.between('sm', 'md')]:{fontSize:"3vw",},
    },
    TableHeadStyle: {
        borderBottom: "none !important",
        backgroundColor: "#f5faff",
        fontWeight: "normal",
        fontSize: "15px",
        width: "30%",
        [theme.breakpoints.between('sm', 'md')]:{fontSize: "22px",},
        [theme.breakpoints.down('xs')]: {fontSize: "14px !important",},
    },
    TableRowStyle: {
        borderBottom: "none !important",
        fontWeight: "500",
        fontSize: "16px",
        width: "30%",
        [theme.breakpoints.between('sm', 'md')]:{fontSize: "18px",},
    },
    PlayArrowOutlinedIconStyle:{color: "#4f4f4f"},
    PlayArrowOutlinedIconOuterSpanStyle:{position: 'absolute', top: '6px', left: '5px'},
    PlayArrowOutlinedIconOuterDivStyle:{position: 'relative'},
    ResumeBtnStyle: {
        padding: "5px 10px",
        borderRadius: "10px",
        backgroundColor: "#ffbe72 !important",
    },
    popup: {
        position: "fixed",
        width: "100%",
        height: "100%",
        top: "0",
        left: "0",
        right: "0",
        bottom: "0",
        margin: "auto",
        backgroundColor: "rgba(0,0,0, 0.5)",
    },
    popup_inner: {
        position: "absolute",
        margin: "auto",
        padding: "40px 20px",
        background: "white",
        borderRadius : "10px",
        [theme.breakpoints.up('lg')]:{
            left: "25%",
            right: "25%",
            top: "25%",
        },
        [theme.breakpoints.between('sm', 'md')]:{
            left: "15%",
            right: "15%",
            top: "30%",
        },
        [theme.breakpoints.down('xs')]:{
            left: "3%",
            right: "3%",
            top: "30%",
        },
    },
    emailInputPopUpStyle:{
        '& div':{
            borderRadius : "10px",
        }
    },
    closePopUpBtnStyle:{
        margin: "20px auto",
        padding: "10px 20px",
        borderRadius: "10px",
        border: "none",
        width:"90px",
        backgroundColor: "darkgrey",
        float:"left",
    },
    savePopUpBtnStyle:{
        margin: "20px auto",
        padding: "10px 20px",
        borderRadius: "10px",
        border: "none",
        width:"90px",
        backgroundColor: "orange",
        float:"right",
    },
    buttonsOuterDiv:{
        width:"80%", margin: "auto"
    }
}));

export const useStylesScore = makeStyles(() => ({
    root: {
        position: 'relative',
    },
    bottom: {
        color: "#c9c9c9",
    },
    top: {
        color: "#ff8300",
        animationDuration: '550ms',
        position: 'absolute',
        left: 0,
    },
    circle: {
        strokeLinecap: 'round',
    },
}));

export const previousAssessmentTableOuterDivStyle = (newQuizAllowed) => {

    const style = makeStyles(theme => ({
        outerDiv: {
            [theme.breakpoints.up('sm')]:{
                marginTop: !newQuizAllowed? "40px": "0",
            },
            [theme.breakpoints.down('sm')]:{
                marginTop: !newQuizAllowed? "30px" : "0",
            },
        },
    }));
    return style();

}