import React from "react";

export const NoPreviousAssessment = (props) => {
    return (
        <React.Fragment>
            <div className={props.noRecordStyle}>
                <div className={props.pTagStyle}>
                    <p>No previous record!</p>
                    <p>Looks like you’re not FUTURE READY yet!</p>
                </div>
            </div>
            <br/>
        </React.Fragment>
    )
}