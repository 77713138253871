import React from 'react';
import LeaderShip from "../../assets/leadership.png";
import Reasoning from "../../assets/process.png";
import Creativity from "../../assets/idea.png";
import Strategies from "../../assets/shapes.png";
import Technology from "../../assets/technology 1.png";
import {skillsBlockStyle} from "../../screens/outcome/QuizScoreStyle";
import {SCREEN_WIDTHS, widthValues} from "../../utils/common";
import PropTypes from "prop-types";
import {withWidth} from "@material-ui/core";

const SkillsBlock = React.memo(function (props) {
    const classes = skillsBlockStyle();
    const isMobile=(props.width === SCREEN_WIDTHS.XS);
    return (
        <React.Fragment>
            {/*it displays the skills basis on which the score is calculated*/}
            <div className={classes.outerDiv}>
                <p className={classes.skillTitle}> How we calculate your Future Readiness? </p>
                <SkillsDisplay isMobile={isMobile}/>
            </div>
        </React.Fragment>
    )
})
SkillsBlock.propTypes={
    width: PropTypes.oneOf(widthValues),
}
export default withWidth()((SkillsBlock));

const SkillImageAndText = (params) => {
    const classes = skillsBlockStyle();
    return (
        <div className={classes.skillDiv} >
            <img className={classes.skillImage} src={params.image} alt={"skill"}/>
            <p className={classes.skillText}>{params.text}</p>
        </div>
    )
}

const SkillsDisplay = React.memo(function (params) {
    const classes = skillsBlockStyle();
    if (params.isMobile)
        return (
            <div className={classes.skillsStyle}>
                <div className={classes.skillsStyleInnerRow}>
                    <SkillImageAndText image={LeaderShip} text={"Leadership & Social Influence"}/>
                    <SkillImageAndText image={Reasoning} text={"Reasoning and Problem Solving"}/>
                </div>
                <div className={classes.skillsStyleInnerRow}>
                    <SkillImageAndText image={Creativity} text={"Creativity & Innovation"}/>
                    <SkillImageAndText image={Strategies} text={"Active Learning & Learning Strategy"}/>
                </div>
                <SkillImageAndText image={Technology} text={"Technology Use & Design"}/>
            </div>
        )
    else
        return (
            <div className={classes.skillsStyle}>
               <div className={classes.skillsStyleInnerRow}>
                    <SkillImageAndText image={LeaderShip} text={"Leadership & Social Influence"}/>
                    <SkillImageAndText image={Reasoning} text={"Reasoning and Problem Solving"}/>
                    <SkillImageAndText image={Creativity} text={"Creativity & Innovation"}/>
               </div>
                <div className={classes.skillsStyleInnerRow}>
                    <SkillImageAndText image={Strategies} text={"Active Learning & Learning Strategy"}/>
                    <SkillImageAndText image={Technology} text={"Technology Use & Design"}/>
                </div>
            </div>
        )
})
