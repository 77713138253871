import React, {useEffect, useMemo} from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {CalculateRelativeScore, CalculateResponseValues} from "./MCMA_Utils";
import {FormGroup} from "@material-ui/core";
import {handleCheckBoxWithImageOptionStyle, optionStyles as useStyles} from '../questionTypesStyles';
import {handleWidthOfImageOptions} from '../../../screens/quiz/quiz_utils';
import RadioGroup from "@material-ui/core/RadioGroup";

//Multiple Choice Multiple Answers With Image Type Quiz (has to always have 4 possible answers)
function MCMAWI(params) {
    const classes = useStyles();
    const ques = params.question;
    const quesTitleHasImage = params.quesTitleHasImage;
    const [isPortrait, setIsPortrait] = React.useState(false);

    const imageUrls = useMemo(() => {
        let opt = [];
        for (let i=0; i < ques.options.length; i++) {
            let option = ques.options[i];
            // get the download url of the option image
            opt.push(option.Key);
        }
        return opt;
    }, [ques]);
    let options = imageUrls;

    useEffect(async()=>{
        setIsPortrait(await handleWidthOfImageOptions(options));
    },[])

    for (const [key] of Object.entries(ques.options)) {
        options.push(key);
    }

    const [state, setState] = React.useState({
        option1: false,
        option2: false,
        option3: false,
        option4: false,
    });

    const {option1, option2, option3, option4} = state;
    //Updates required values for response and relative score
    useEffect(() => {
        let response = CalculateResponseValues(option1, option2, option3, option4, options);
        params.setResponse(response.toString());
        params.setRelativeScore(CalculateRelativeScore(ques, response));

    }, [option1, option2, option3, option4, options, params, ques]);

    const handleChange = (event) => {
        setState({...state, [event.target.name]: event.target.checked});
    };

    const RenderOption = (params) => {
        const classNames = handleCheckBoxWithImageOptionStyle(state[params.optionId], params.isOptionOnRight, isPortrait, quesTitleHasImage);
        return (
            <FormControlLabel
                className={classNames.optionCls}
                label={<img src={params.optionImage} alt={'option'} className={classNames.imageLabel}/>}
                control={<Checkbox checked={params.checked} className={classNames.checkBox} onChange={handleChange} name={params.optionId}/>}
            />
        )
    };

    return (
        <FormGroup className={(isPortrait && !quesTitleHasImage) ? classes.portraitOuterDiv : classes.imageOptionsOuterDiv}>
            <div>
                <RenderOption optionId={"option1"} optionImage={imageUrls[0]} isOptionOnRight={false} checked={option1}/>
                <RenderOption optionId={"option2"} optionImage={imageUrls[1]} isOptionOnRight={(!(isPortrait && !quesTitleHasImage))} checked={option2}/>
            </div>
            <div>
                <RenderOption optionId={"option3"} optionImage={imageUrls[2]} isOptionOnRight={false} checked={option3}/>
                <RenderOption optionId={"option4"} optionImage={imageUrls[3]} isOptionOnRight={(!(isPortrait && !quesTitleHasImage))} checked={option4}/>
            </div>
        </FormGroup>
    )
};
export default (React.memo(MCMAWI));