//Type in the Answer Quiz Question
import React, {useMemo} from "react";
import {ThemeProvider} from "@material-ui/core/styles";
import {TextField, withWidth} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import {TIAMV_theme as theme, TIAMV_themeMob as themeMob} from '../questionTypesStyles';
import PropTypes from "prop-types";
import {SCREEN_WIDTHS, widthValues} from "../../../utils/common";

function TIAMV(params) {
    const ques = params.question;
    const isMobile=(params.width === SCREEN_WIDTHS.XS);

    const quesOptions = useMemo(() => {
        let opt = [];
        for (let i=0; i < ques.options.length; i++) {
            let option = ques.options[i];
            // get the download url of the option image
            opt.push(option.Key);
        }
        return opt;
    }, [ques]);

    const [selected, setSelected] = React.useState([]);

    //Calculates the relative score of the chosen option
    const calculateRelativeScore = (ansValues) => {
        let selectedScore = 0;
        let maxScore = 0;
        for (let i=0; i < ques.options.length; i++) {
            let option = ques.options[i];
            let key = option.Key;
            let value = option.Value;
            if(ansValues.includes(key)) {
                selectedScore = selectedScore + parseFloat(value.toString());
            }
            //Don't add negative scoring to the Maximum score calculation
            maxScore = maxScore + (parseFloat(value.toString()) >= 0 ? parseFloat(value.toString()) : 0);
        }

        selectedScore = selectedScore < 0 ? 0 : selectedScore; //If selected score negative, set it to Zero
        maxScore = maxScore === 0 ? 1 : maxScore; //If maximum score zero, set it to One
        return (selectedScore/maxScore).toFixed(1).toString();
    }

    //Handles the event whenever another option is selected
    const handleChange = (event, values) => {
        setSelected(values);
        params.setResponse(values.toString());
        params.setRelativeScore(calculateRelativeScore(values));
    };

    return (
        <ThemeProvider theme={isMobile ? themeMob : theme}>
            <Autocomplete
                multiple
                value={selected}
                onChange={handleChange}
                id="tags-standard"
                options={quesOptions}
                getOptionLabel={(option) => option}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="standard"
                        label="Remember, there's negative marking for wrong selections"
                        placeholder="Answers"
                        style={{padding: "10px"}}
                    />
                )}
            />
        </ThemeProvider>
    );
}
TIAMV.propTypes={
    width: PropTypes.oneOf(widthValues),
}
export default withWidth()(TIAMV);