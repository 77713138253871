// function to check to render the options i portrait view or not
export const handleWidthOfImageOptions = async (options) => {
    // run for all the options of the question
    for (let index = 0; index < options.length; index++) {
        let imgUrl = options[index];
        // return true if the image is of portrait view
        if (await addImageProcess(imgUrl)) return true;
    }
    return false;
}

// function to load the image and check is the image is of portrait view or not
function addImageProcess(src) {
    return new Promise((resolve, reject) => {
        let img = new Image()
        img.onload = () => {
            if ((img.height / img.width) >= (9 / 16)) {
                resolve(true)
            }
        }
        img.onerror = reject
        img.src = src
    })
}