//Drag and Drop Quiz Question
import React, {useEffect, useState} from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {Shuffle} from "../../../services/Database";

export default function DND(params) {
    const ques = params.question;
    const [items, setItems] = useState([]);

    useEffect(() => {
        let quesOptions = ques.options.slice(); //Clone options to another array and then shuffle
        setItems(Shuffle(quesOptions));
    }, [ques])

// a little function to help us with reordering the result
    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const grid = 12;

    const getItemStyle = (isDragging, draggableStyle) => ({
        // some basic styles to make the items look a bit nicer
        userSelect: "none",
        padding: (grid-3) * 2,
        margin: `0 0 ${grid-2}px 0`,
        borderRadius: "15px",

        // change background colour if dragging
        background: isDragging ? "#ffdebc" : "white",

        // styles we need to apply on draggables
        ...draggableStyle
    });

    const getListStyle = isDraggingOver => ({
        background: isDraggingOver ? "#ffdebc" : "rgba(253, 212, 169, 0.38)",
        padding: grid,
        width:"80%",
        margin: "auto"
    });

    //Calculates the relative score of the chosen answers
    function calculateRelativeScore(newItems) {
        let count=0;
        for(let i=0;i<ques.options.length;i++){
            if(ques.options[i] === newItems[i]){
                count++;
            }
        }
        let max = ques.options.length === 0 ? 1 : ques.options.length;
        return (count / max).toFixed(2).toString();
    }

    //Whenever drag event is completed
    const onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const newItems = reorder(
            items,
            result.source.index,
            result.destination.index
        );

        setItems(newItems);
        params.setResponse(newItems.toString());
        params.setRelativeScore(calculateRelativeScore(newItems));
    }

    //UI
    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                    <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                    >
                        {items.map((item, index) => (
                            <Draggable key={index} draggableId={index.toString()} index={index}>
                                {(provided, snapshot) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={getItemStyle(
                                            snapshot.isDragging,
                                            provided.draggableProps.style
                                        )}
                                    >
                                        {item}
                                    </div>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
}
