import React, {useContext, useEffect} from "react";
import FullCloud from "../../assets/full_cloud.png";
import RightHalfCloud from "../../assets/AdditionalDetailsRightHalfCloud.png";
import RocketAsset from "../../assets/rocket_asset.png";
import RenderFormAdditionalDetails from "./RenderFormAdditionalDetails";
import {editDetailsStyles as useStyles} from "../../screens/dashboard/DashboardStyleSheet";
import PropTypes from "prop-types";
import {SCREEN_WIDTHS, widthValues} from "../../utils/common";
import {withWidth} from "@material-ui/core";
import ArrowArchAsset from "../../assets/arrow_arch_asset.png";
import AuthContext from "../../utils/AuthContext";
import {setAnalyticsScreen} from "../../services/Analytics";
import {Constants} from "../../utils/Constants";

const EditPersonalDetails = (params) => {

    const classes = useStyles();
    const isMobile=(params.width === SCREEN_WIDTHS.XS);
    const isTablet=((params.width === SCREEN_WIDTHS.SM)||(params.width === SCREEN_WIDTHS.MD));

    useEffect(() => {
        setAnalyticsScreen(Constants.screen_editProfile)
    })

    return (
        <div className={classes.AdditionalDetailsBackground}>
            <RenderLeftSide userName={params.firstName} logOut={params.logOut} classes={classes} isMobile={isMobile} isTablet={isTablet}/>
            <RenderFormAdditionalDetails params={params} classes={classes}/>
        </div>
    )
}

EditPersonalDetails.propTypes={
    width: PropTypes.oneOf(widthValues),
}
export default withWidth()((EditPersonalDetails));

const RenderLeftSide = (params) => {
    const classes = params.classes;

    if (params.isMobile || params.isTablet)
        return (
            <div className={classes.TopLeftVectorStyle}>
                <img src={params.isTablet ? ArrowArchAsset : RightHalfCloud} alt="cloud"
                     className={params.isTablet ? classes.additionalArchArrowStyle : classes.additionalFullCloudStyle}/>
                <div className={classes.InfoStyle}>
                    <p className={classes.HiTextStyle}>Hi, {params.userName}</p>
                    <p className={classes.GreetingsUserStyle}>Welcome Home!<br/></p>
                    <p className={classes.text_style}>
                        Please fill the following information to help us build a quiz experience unique to you:
                    </p>
                </div>
            </div>
        )
    else
        return (
            <div className={classes.TopLeftVectorStyle}>
                <img src={FullCloud} alt="Full cloud" className={classes.CloudAlignmentStyle}/>
                <div className={classes.InfoStyle}>
                    <p className={classes.HiTextStyle}>Hi, {params.userName}</p>
                    <p className={classes.GreetingsUserStyle}>Welcome Home!</p>
                    <p className={classes.text_style}>
                        Already, more than 100k kids have taken the FRS Assessment and have started their Future Readiness Journey.
                    </p>
                    <button id={"logout_btn"} className={classes.btn_style} onClick={params.logOut}>Logout</button>
                </div>
                <img src={RocketAsset} alt="RocketAsset" className={classes.rocketAssetAdditionalDetailsStyle}/>
                <img src={FullCloud} alt="Full cloud" className={classes.additionalFullCloudStyle}/>
            </div>
        )
}




