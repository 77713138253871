//Type in the Answer Quiz Question
import React from "react";
import {ThemeProvider} from "@material-ui/core/styles";
import {TextField} from "@material-ui/core";
import {TIATheme as theme} from '../questionTypesStyles';

export default function TIA(params) {
    const ques = params.question;
    const [answer, setAnswer] = React.useState('');

    //Calculates the relative score of the chosen option
    const calculateRelativeScore = (ansValue) => {
        let allValues = [];
        let selectedValue = 0;
        for (let i=0; i < ques.options.length; i++) {
            let option = ques.options[i];
            let key = option.Key;
            let value = option.Value;
            if (key.toLowerCase() === ansValue.toLowerCase()) {
                selectedValue = parseFloat(value.toString())
            }
            allValues.push(parseFloat(value.toString()));
        }
        let max = allValues.reduce(function (a, b) {
            return Math.max(a, b);
        });
        max = max === 0 ? 1 : max;
        return (selectedValue / max).toFixed(2).toString();
    }

    //Handles the event whenever another option is selected
    const handleChange = (event) => {
        setAnswer(event.target.value);
        params.setResponse(event.target.value);
        params.setRelativeScore(calculateRelativeScore(event.target.value));
    };

    return (
        <ThemeProvider theme={theme}>
            <TextField
                id="standard-full-width"
                label=""
                placeholder="Type your answer here"
                fullWidth
                multiline
                variant="outlined"
                rows={4}
                margin="normal"
                value={answer}
                onChange={handleChange}
                InputLabelProps={{shrink: true,}}
            />
        </ThemeProvider>
    );
}