import React, {useContext, useEffect, useState} from "react";
import MCSA from '../../components/quiz/mcsa/MCSA';
import MCMA from "../../components/quiz/mcma/MCMA";
import MCSAWI from "../../components/quiz/mcsa/MCSAWI";
import MCMAWI from "../../components/quiz/mcma/MCMAWI";
import MCSAWDI from "../../components/quiz/mcsawdi/MCSAWDI";
import {
    UpdateQuizAttempt,
    SubmitQuizFinally, loadImages
} from '../../services/Database';
import {
    WithImageTheme, WrittenTheme, WrittenThemeMob, QuestionsLinearProgress, styles as useStyles, handleTitleImageStyle
} from './quizStyle';
import QuizScore from '../outcome/QuizScore';
import Countdown from 'react-countdown';
import TimerIcon from '@material-ui/icons/Timer';
import CircularProgress from '@material-ui/core/CircularProgress';
import {ThemeProvider} from '@material-ui/core/styles';
import TIA from "../../components/quiz/tia/TIA";
import DND from "../../components/quiz/dnd/DND";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import TIAMV from "../../components/quiz/tia/TIAMV";
import UserFeedback from "../feedback/UserFeedback";
import LoadingScore from "../outcome/LoadingScore";
import {QuestionAttemptModel, SubmitAssessmentModel} from "../../models/FRSDataModels";
import AuthContext from "../../utils/AuthContext";
import {Analytics, setAnalyticsScreen} from "../../services/Analytics";
import {Constants} from "../../utils/Constants";

export default function Quiz(params) {

    // the user is a useContext variable having values of display name and email of the user
    let attemptId = params.questions[0]; //Gets the current quiz attempt id from this
    let quizQuestions = params.questions[1] //Gets the current quiz questions from this
    const user = useContext(AuthContext).user;
    const [currentQuesIndex, setCurrentQuesIndex] = useState(0);
    const [currentQuestion, setCurrentQuestion] = useState(quizQuestions[currentQuesIndex]);
    const [response, setResponse] = useState('');
    const [relativeScore, setRelativeScore] = useState('0');
    const [isLastQuestion, setLastQuestion] = useState(quizQuestions.length === 1);
    const [moveToScore, setMoveToScore] = useState(false);
    const [haveDisappearingImage, setHaveDisappearingImage] = useState(false);
    const [renderPortraitImage, setRenderPortraitImage] = useState(false);
    const [timeAllotted, setTimeAllotted] = useState(currentQuestion.timeallowed ? parseInt(currentQuestion.timeallowed.toString()) : 30);
    const [timeSpent, setTimeSpent] = useState(0); //Needed to keep the screen refreshing every second
    const [progress, setProgress] = useState(0);
    const [questionsSubCategories, setQuestionsSubCategories] = useState([]);
    const [userSubmitOutcome, setUserSubmitOutcome] = useState(new SubmitAssessmentModel());
    const [quesTitleHasImage, setQuesTitleHasImage] = useState((typeof currentQuestion.titleimage !== 'undefined') && (currentQuestion.titleimage !== ''));
    const [titleImageUrl, setTitleImageUrl] = useState('');
    const [loading, setLoading] = useState(true);
    const [submitButtonClicked, setSubmitButtonClicked] = useState(false);
    const [feedbackProvided, setFeedbackProvided] = useState(false);
    const [disableNSButton, setDisableNSButton] = useState(false)
    const classes = useStyles();

    //Handle Feedback provided event
    const handleFeedbackProvided = () => {
        setFeedbackProvided(true);
    }

    //Update values as necessary
    useEffect(() => {
        setAnalyticsScreen(Constants.screen_quiz)

        // check for question with image
        if (quesTitleHasImage) {
            handleQuestionImageRendering(currentQuestion.questiontype, currentQuestion.titleimage)
        } else {
            setLoading(false);
        }
        // Setup the timer function
        const timerId = setInterval(() => {
            setTimeSpent((t) => t + 1);
        }, 1000);

        return () => {
            clearInterval(timerId);
        }
    }, [timeAllotted, isLastQuestion, quesTitleHasImage]);

    // download images of next 10 question in the array of questions
    const downloadQuesImages = async () => {
        let startIndex = 10;
        let endIndex = quizQuestions.length;
        // load the images of questions from startIndex to endIndex
        params.questions[1] = await (loadImages(quizQuestions, startIndex, (endIndex)));
    }

    // load images if the question title has and check the question is with disappearing image or question title wth image
    const handleQuestionImageRendering = (type, url) => {

        // question type of the question with disappearing image
        if (type === 'ymxGeahhePiljtFhGDcI') {
            setHaveDisappearingImage(true);
            setQuesTitleHasImage(false);
        } else {
            setTitleImageUrl(url);
        }
        const imageToLoad = new Image();
        imageToLoad.src = url;
        imageToLoad.onload = () => {
            // if image is of portrait view, set the variable to true
            if ((imageToLoad.height / imageToLoad.width) > (9 / 16)) {
                setRenderPortraitImage(true);
            }
            setTimeSpent(0);
            setLoading(false);
        }
    }

    // Handles the triggers when timeout happens
    const handleTimeout = async () => {
        if (isLastQuestion) {
            await handleSubmitQuiz();
        } else {
            await handleGoToNextQuestion();
        }
    }

    // Renderer for countdown timer
    const countdownRenderer = (time) => {
        return (
            <div className={classes.timerStyle}>
                <TimerIcon/>
                <span> <b>{time.minutes}:{time.seconds}</b> </span>
            </div>
        )
    };

    //Performs functions to allow the user to move to the next question
    const handleGoToNextQuestion = async () => {
        Analytics(Constants.screen_quiz, Constants.track_goToNextQuestion, Constants.track_click)
        setDisableNSButton(true);
        setQuestionsSubCategories(questionsSubCategories.concat(currentQuestion.subcategory));
        // console.log("current cate = ", currentQuestion.subcategory);
        let questionAttempt = new QuestionAttemptModel();
        questionAttempt.questionid = currentQuestion.id;
        questionAttempt.response = response;
        questionAttempt.relativescore = relativeScore;

        await UpdateQuizAttempt(attemptId, questionAttempt);
        setResponse('');
        setRelativeScore('0');
        setTimeSpent(0);
        let nextQuesIndex = currentQuesIndex + 1;
        setProgress((nextQuesIndex / (quizQuestions.length)) * 100);
        //If last question is reached
        if (nextQuesIndex >= quizQuestions.length - 1) {
            setLastQuestion(true);
        }
        setCurrentQuesIndex(nextQuesIndex);
        setCurrentQuestion(quizQuestions[nextQuesIndex]);
        setTimeAllotted(quizQuestions[nextQuesIndex].timeallowed ? parseInt(quizQuestions[nextQuesIndex].timeallowed.toString()) : 30);
        if (typeof quizQuestions[nextQuesIndex].titleimage !== 'undefined' && quizQuestions[nextQuesIndex].titleimage !== "") {
            setQuesTitleHasImage(true);
            setLoading(true);
            handleQuestionImageRendering(quizQuestions[nextQuesIndex].questiontype, quizQuestions[nextQuesIndex].titleimage);
        } else {
            setQuesTitleHasImage(false);
        }
        setDisableNSButton(false);
        if (currentQuesIndex === 2) {
            downloadQuesImages();
        }
    }

    //On the last question, this function is called
    const handleSubmitQuiz = async () => {
        Analytics(Constants.screen_quiz, Constants.track_submit_quiz, Constants.track_click)
        setDisableNSButton(true);
        setSubmitButtonClicked(true);
        setQuestionsSubCategories(questionsSubCategories.concat(currentQuestion.subcategory));

        //No need to repeat the code, this will submit the response anyway
        let questionAttempt = new QuestionAttemptModel();
        questionAttempt.questionid = currentQuestion.id;
        questionAttempt.response = response;
        questionAttempt.relativescore = relativeScore;

        await UpdateQuizAttempt(attemptId, questionAttempt);

        //Submit the final attempt and move to Score page
        let submitQuizValues = await SubmitQuizFinally(attemptId, user);
        setDisableNSButton(false);
        setUserSubmitOutcome(submitQuizValues);
        setMoveToScore(true);
    }

    //UI
    try {
        // i.e. quiz is going on
        if (!submitButtonClicked) {
            return (
                <HandleRenderingOfCurrentQuestion
                    loading={loading}
                    haveDisappearingImage={haveDisappearingImage}
                    timeSpent={timeSpent}
                    setHaveDisappearingImage={setHaveDisappearingImage}
                    countdownRenderer={countdownRenderer}
                    titleimage={currentQuestion.titleimage}
                    timeAllotted={timeAllotted}
                    quesTitleHasImage={quesTitleHasImage}
                    renderPortraitImage={renderPortraitImage}
                    currentQuestion={currentQuestion}
                    titleImageUrl={titleImageUrl}
                    setResponse={setResponse}
                    setRelativeScore={setRelativeScore}
                    isLastQuestion={isLastQuestion}
                    handleGoToNextQuestion={handleGoToNextQuestion}
                    disableNSButton={disableNSButton}
                    handleSubmitQuiz={handleSubmitQuiz}
                    handleTimeout={handleTimeout}
                    progress={progress}
                />
            )
        } else if (feedbackProvided && moveToScore) {
            return (
                <QuizScore attemptId={attemptId} UserSubmitOutcome={userSubmitOutcome}
                           questionsSubCategories={questionsSubCategories}/>
            )
        } else if (feedbackProvided && !moveToScore) {
            return (
                <LoadingScore/>
            )
        } else {
            return (
                <UserFeedback attemptId={attemptId} handleFeedbackProvided={handleFeedbackProvided}/>
            )
        }
    } catch (err) {
        console.log("error occurred : ", err);
    }

}
// handle the overall rendering of current question
    function HandleRenderingOfCurrentQuestion(params) {
        const classes = useStyles();
        const {
            loading, haveDisappearingImage, timeSpent, setHaveDisappearingImage, countdownRenderer, titleimage
        } = params;
        return (
            <div className={classes.outermostQuizDiv}>
                <div className={classes.quizHeaderStyle}>
                    {(!loading) ?
                        haveDisappearingImage ?
                            <RenderDisappearImage
                                timeSpent={timeSpent}
                                setHaveDisappearingImage={setHaveDisappearingImage}
                                countdownRenderer={countdownRenderer}
                                titleimage={titleimage}/>
                            :
                            <SimpleQuestionUI timeAllotted={params.timeAllotted}
                                              timeSpent={params.timeSpent}
                                              countdownRenderer={params.countdownRenderer}
                                              quesTitleHasImage={params.quesTitleHasImage}
                                              renderPortraitImage={params.renderPortraitImage}
                                              currentQuestion={params.currentQuestion}
                                              titleImageUrl={params.titleImageUrl}
                                              setResponse={params.setResponse}
                                              setRelativeScore={params.setRelativeScore}
                                              isLastQuestion={params.isLastQuestion}
                                              handleGoToNextQuestion={params.handleGoToNextQuestion}
                                              disableNSButton={params.disableNSButton}
                                              handleSubmitQuiz={params.handleSubmitQuiz}
                                              progress={params.progress}
                                              handleTimeout={params.handleTimeout}
                            />

                        :
                        <div className={classes.circularProgressDiv}>
                            <CircularProgress/>
                        </div>
                    }
                </div>
            </div>
        )
    }

// handle the rendering of question not having disappearing image
    function SimpleQuestionUI(params) {
        const classes = useStyles();
        const {
            timeAllotted,
            timeSpent,
            countdownRenderer,
            quesTitleHasImage,
            renderPortraitImage,
            currentQuestion,
            titleImageUrl,
            setResponse,
            setRelativeScore,
            isLastQuestion,
            handleGoToNextQuestion,
            disableNSButton,
            handleSubmitQuiz,
            progress,
            handleTimeout
        } = params;
        return (
            <div className={classes.quizBottomVectorStyle}>
                <div>
                    <Countdown
                        date={new Date().getTime() + ((timeAllotted - timeSpent) * 1001)}
                        onComplete={handleTimeout}
                        renderer={countdownRenderer}
                    />
                    <div className={classes.outer}>
                        {quesTitleHasImage ?
                            <RenderQuestionHavingTitleImage
                                renderPortraitImage={renderPortraitImage}
                                currentQuestion={currentQuestion}
                                titleImageUrl={titleImageUrl}
                                setResponse={setResponse}
                                setRelativeScore={setRelativeScore}
                                quesTitleHasImage={quesTitleHasImage}
                            />
                            :
                            <RenderQuestionWithoutTitleImage
                                currentQuestion={currentQuestion}
                                setResponse={setResponse}
                                setRelativeScore={setRelativeScore}
                                quesTitleHasImage={quesTitleHasImage}
                            />
                        }
                        <ButtonAndProgressBar
                            isLastQuestion={isLastQuestion}
                            handleGoToNextQuestion={handleGoToNextQuestion}
                            disableNSButton={disableNSButton}
                            handleSubmitQuiz={handleSubmitQuiz}
                            progress={progress}
                        />
                    </div>
                </div>
            </div>
        )
    }

// handle the rendering of question having title image
    function RenderQuestionHavingTitleImage(params) {
        const classes = useStyles();
        const {
            renderPortraitImage,
            currentQuestion,
            titleImageUrl,
            setResponse,
            setRelativeScore,
            quesTitleHasImage
        } = params;
        const titleStyle = handleTitleImageStyle(renderPortraitImage);
        return (
            <ThemeProvider theme={WithImageTheme}>
                <div className={classes.withImageDiv}>
                    <FormControl component="fieldset" fullWidth={true} className={classes.imageQuesStyle}>
                        <div className={classes.formControlDiv}>
                            <FormLabel component="legend" className={classes.quesStyle}>
                                <b className={classes.imageQues}>{currentQuestion.title}</b>
                                <img className={titleStyle} src={titleImageUrl} alt={"Question"}/>
                            </FormLabel>
                        </div>
                        <div className={classes.QWI_OptionsDiv}>
                            {renderCurrentQuestionOptions(currentQuestion, setResponse, setRelativeScore, quesTitleHasImage)}
                        </div>
                    </FormControl>
                </div>
            </ThemeProvider>
        )
    }

// handle the rendering of question not having title image
    function RenderQuestionWithoutTitleImage(params) {
        const classes = useStyles();
        const {currentQuestion, setResponse, setRelativeScore, quesTitleHasImage} = params;
        return (
            <ThemeProvider theme={params.isMobile ? WrittenThemeMob : WrittenTheme}>
                <div className={classes.withImageDiv}>
                    <FormControl component="fieldset" className={classes.writtenQuesStyle}>
                        <FormLabel className={`${classes.quesStyle} ${classes.quesStyleWritten}`} component="legend">
                            <b className={classes.writtenTitleStyle}>{currentQuestion.title}</b>
                        </FormLabel>
                        <div className={classes.optionGroupStyle}>
                            {renderCurrentQuestionOptions(currentQuestion, setResponse, setRelativeScore, quesTitleHasImage)}
                        </div>
                    </FormControl>
                </div>
            </ThemeProvider>
        )
    }

//Renders the question according to the question type
    const renderCurrentQuestionOptions = (currentQuestion, setResponse, setRelativeScore, quesTitleHasImage) => {
        const classes = useStyles();

        switch (currentQuestion.questiontype) {
            case 'u7lCushFJnuJHtGOBBgh': //Multiple Choice Multiple Answers
                return <MCMA question={currentQuestion} setResponse={setResponse} setRelativeScore={setRelativeScore}/>;
            case 'Vl2YiO6aKk58mvwKCt1T': //Multiple Choice Single Answer
                return <MCSA question={currentQuestion} setResponse={setResponse} setRelativeScore={setRelativeScore}/>;
            case 'UkeHEltZRkqoQByG6eGx': //Type in the Answer
                return (
                    <div className={quesTitleHasImage ? "" : classes.outerOptionDiv}>
                        <TIA question={currentQuestion} setResponse={setResponse} setRelativeScore={setRelativeScore}/>
                    </div>
                );
            case '7fkHXSIKyOtbooiP6jIn': //Multiple Choice Single Answers With Image
                return (
                    <div className={quesTitleHasImage ? "" : classes.outerOptionDiv}>
                        <MCSAWI question={currentQuestion} setResponse={setResponse} quesTitleHasImage={quesTitleHasImage}
                                setRelativeScore={setRelativeScore}/>
                    </div>
                );
            case 'ImoEwlhUHgRElqRR6LQb': //Multiple Choice Multiple Answers With Image
                return (
                    <div className={quesTitleHasImage ? "" : classes.outerOptionDiv}>
                        <MCMAWI question={currentQuestion} setResponse={setResponse} quesTitleHasImage={quesTitleHasImage}
                                setRelativeScore={setRelativeScore}/>
                    </div>
                );
            case 'Ow1ECxQuiRX0O605gtQp': //Drag and Drop
                return <DND question={currentQuestion} setResponse={setResponse} setRelativeScore={setRelativeScore}/>;
            case 'JUsasSu3vlG2UzehGus8': //Type in the Answer (Multiple values)
                return (
                    <div className={quesTitleHasImage ? "" : classes.outerOptionDiv}>
                        <TIAMV question={currentQuestion} setResponse={setResponse} setRelativeScore={setRelativeScore}/>
                    </div>
                );
            case 'ymxGeahhePiljtFhGDcI': //Multiple Choice Single Answer with disappearing image
                return <MCSAWDI question={currentQuestion} setResponse={setResponse} setRelativeScore={setRelativeScore}/>;
            default:
                return <div><p>Unknown {currentQuestion.questiontype}</p></div>
        }
    }

// next or submit button and progress bar of questions attempted
    function ButtonAndProgressBar(params) {
        const classes = useStyles();
        const {isLastQuestion, handleGoToNextQuestion, disableNSButton, handleSubmitQuiz, progress} = params;

        return (
            <div className={classes.lowerDiv}>
                {!isLastQuestion ?
                    <button className={`${classes.quizButton} ${classes.nextBtn}`}
                            onClick={handleGoToNextQuestion} disabled={disableNSButton}>
                        <p className={classes.btnText}><b>Next</b></p>
                    </button>
                    :
                    <button disabled={disableNSButton} id={"submitquiz"} onClick={handleSubmitQuiz}
                            className={`${classes.quizButton} ${classes.submitBtn}`}>
                        <p className={classes.btnText}>Submit</p>
                    </button>
                }
                <QuestionsLinearProgress variant="determinate"
                                         className={classes.QuestionsLinearProgressStyle}
                                         value={progress}/>
            </div>
        )
    }

// render the disappearing image if the question is of disappearing image type
    function RenderDisappearImage(params) {
        const classes = useStyles();
        const {timeSpent, setHaveDisappearingImage, countdownRenderer, titleimage} = params;
        return (
            <div className={classes.quizBottomVectorStyle}>
                <Countdown
                    date={new Date().getTime() + ((10 - timeSpent) * 1000)}
                    onComplete={async () => {
                        setHaveDisappearingImage(false)
                    }}
                    renderer={countdownRenderer}
                />

                <img className={classes.displayTitleImageStyle} src={titleimage} alt={"Question"}/>
            </div>
        )
    }
