/**
 * This is User Model to store the values of the user
 * @constructor
 */
export function UserModel() {
    this.uid = "";
    this.phone = "";
    this.countrycode = "";
    this.dob = "";
    this.frsrefcode = "";
    this.frsuser = false;
    this.frscreatedtimestamp = "";
    this.lastmodifiedtimestamp = "";
    this.username = "";
    this.email = "";
    this.lastname = "";
    this.firstname = "";
    this.usrimgurl = "";
    this.ipaddresses = {};
}


/**
 * This function is used to create a user model from the json
 * @param json: takes json as input
 * @returns {UserModel}: return UserModel with provided json
 * @constructor
 */
export function UserModelFromJson(json) {
    let user = new UserModel();
    user.uid = json['uid'];
    user.phone = json['phone'];
    user.countrycode = json['countrycode'];
    user.dob = json['dob'];
    user.frsrefcode = json['frsrefcode'];
    user.frsuser = json['frsuser'];
    user.frscreatedtimestamp = json['frscreatedtimestamp'];
    user.lastmodifiedtimestamp = json['lastmodifiedtimestamp'];
    user.username = json['username'];
    user.email = json['email'];
    user.lastname = json['lastname'];
    user.firstname = json['firstname'];
    user.usrimgurl = json['usrimgurl'];
    user.ipaddresses = json['frsipddresses']
    return user;
}


/**
 * Function to create the jsonified version of a user
 * @param user takes user model as a parameter to convert it to json
 * @returns {string} returns jsonified string of the user for the requests
 * @constructor
 */
export function UserModelToJson(user) {
    return JSON.stringify({
        "uid": user.uid,
        "phone": user.phone,
        "countrycode": user.countrycode,
        "dob": user.dob,
        "frsrefcode": user.frsrefcode,
        "frsuser": user.frsuser,
        "frscreatedtimestamp": user.frscreatedtimestamp,
        "lastmodifiedtimestamp": user.lastmodifiedtimestamp,
        "username": user.username,
        "email": user.email,
        "lastname": user.lastname,
        "firstname": user.firstname,
        "usrimgurl": user.usrimgurl,
        "frsipddresses": user.frsipddresses,
    });
}