import dotenv from 'dotenv';
import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";
import {getStorage, ref, getDownloadURL} from "firebase/storage";
import {Api} from "./Api";
import {Constants} from "../utils/Constants";
import {
    AssessmentModelFromJson,
    QuestionAttemptModelToJson,
    QuestionModelFromJson, SubmitAssessmentModel,
    SubmitAssessmentModelFromJson, UserFeedbackModelToJson
} from "../models/FRSDataModels";
import {UserModelFromJson, UserModelToJson} from "../models/UserModels";
const publicIp = require('public-ip');
// set the environment
dotenv.config()
require('firebase/auth')


export const FirebaseSetup = initializeApp({
    apiKey: "AIzaSyBIfjcHhrGE8lcg8qyHWyIHtZjQtVz2Roc",
    authDomain: "itinker-frs-tool.firebaseapp.com",
    projectId: "itinker-frs-tool",
    storageBucket: "itinker-frs-tool.appspot.com",
    messagingSenderId: "458429371271",
    appId: "1:458429371271:web:3d59100a7e8ed1636d7922",
    measurementId: "G-3YBWG5CM51"
});

// assigning firestore storage
const storage = getStorage(FirebaseSetup);

export const analytics = getAnalytics(FirebaseSetup);


//To check if current user (if any) is authenticated or not
export const CheckUserAuth = async () => {
    return await Api(Constants.checkAuth, {}, "");
}

//To send a given OTP to the user
export const SendOTP = async (countryCode, phone) => {
    let otp = generateOTPNumber();
    let res = await Api(Constants.sendOTP, {}, "", {
        "countrycode": countryCode.toString(),
        "phone": phone.toString(),
        "otp": otp.toString(),
    })
    let existingUser = false;
    let existingFRSUser = false
    let otpSent = false;
    if (res !== "" && res !== null) {
        existingUser = res.existinguser;
        existingFRSUser = res.existingsource;
        otpSent = res.otp_sent;
    }
    return [otp, existingUser, existingFRSUser, otpSent];
}

//To login an existing user to the application
export const LoginUser = async (countryCode, phone) => {
    const ipAddress = await publicIp.v4();
    return await Api(Constants.login, {}, "", {
        "countrycode": countryCode.toString(),
        "phone": phone.toString(),
        "ipaddress": ipAddress === undefined ? "" : ipAddress.toString(),
    })
}

// function which adds new user information to DB
export const AddUserData = async (countryCode, phone) => {
    const ipAddress = await publicIp.v4();
    return await Api(Constants.addUser, {}, "", {
        "countrycode": countryCode.toString(),
        "phone": phone.toString(),
        "ipaddress": ipAddress === undefined ? "" : ipAddress.toString(),
    })
}

// function which updates data of user in the collection Users to store user Information
export const UpdateUserData = async (userData) => {
    let res = await Api(Constants.updateUserData, {}, UserModelToJson(userData));
    if (res !== "" && res !== null) {
        userData = new UserModelFromJson(res)
    }
    return userData;
}

//dashboard.js : Builds a unique user quiz, stores its initial state in the DB and sends back the question data
export const BuildUserQuiz = async (user) => {
    let age = getAgeFromDob(user.dob)
    let res = await Api(Constants.buildAssessment, {"age": age}, "");
    let finalQuizQuestions = [];
    let attemptId = "";
    if (res !== "" && res !== null) {
        let quizQuestions = res.Questions
        quizQuestions.forEach((question) => {
            let ques = new QuestionModelFromJson(question)
            finalQuizQuestions.push(ques)
        })
        attemptId = res.id
        //Shuffle the questions to crate a random flow
        Shuffle(finalQuizQuestions);
    }
    return [attemptId, finalQuizQuestions];
}

// function which updates a question attempt for a given user
export const UpdateQuizAttempt = async (attemptid, questionAttempt) => {
    await Api(Constants.updatesAssessmentAttempt, {"id": attemptid}, QuestionAttemptModelToJson(questionAttempt));
}

//quiz.js : function which makes the final quiz submission
export const SubmitQuizFinally = async (attemptId, user) => {
    let res = await Api(Constants.submitAssessment, {"id": attemptId}, "");
    let submitModel = new SubmitAssessmentModel();
    if (res !== "" && res !== null) {
        submitModel = SubmitAssessmentModelFromJson(res)
    }
    // download the bucket badge image to http url badge image
    await GetImageDownloadUrl(submitModel.badgeurl).then((image) => {
        submitModel.badgeurl = image
    });

    // handle the call to api to generate and send FRS certificate to the user via email
    await generateAndSendCertificate(submitModel, user, attemptId)
    return submitModel;
}

//handle generate certificate and send the email with certificate to the user
export const generateAndSendCertificate = async (assessmentDetails, user, attemptID) => {
    const send = await Api(
        Constants.sendCertificateEmail,
        {
            'badgeurl': assessmentDetails.badgeurl,
            'name': user.firstname + " " + user.lastname,
            'email': user.email,
            'attemptid': attemptID
        }, ""
    );
    if (send) {
        console.log(" function returned true")
    }
}

// function which adds rows in the collection called UserFeedback to store user feedback information
export const AddUserFeedback = async (feedback) => {
    await Api(Constants.addFeedback, {}, UserFeedbackModelToJson(feedback));
}

//Retrieves the quiz attempt history for the user
export const GetUserQuizAttempts = async () => {
    let res = await Api(Constants.listAssessments, {}, "");
    let assessments = []
    if (res !== "" && res !== null) {
        res.forEach((assessment) => {
            let assess = new AssessmentModelFromJson(assessment)
            assessments.push(assess)
        })
    }
    return assessments
}

//dashboard.js : Retrieves the set of questions still unanswered by the user after he/she clicks on Resume Quiz button
export const RetrieveOngoingQuizQuestions = async (quizAttempt) => {
    let res = await Api(Constants.getOngoingAssessmentQues, {"id": quizAttempt.id}, "");
    let questions = []
    if (res !== "" && res !== null) {
        res.forEach((question) => {
            let ques = new QuestionModelFromJson(question)
            questions.push(ques)
        })
        //Shuffle the questions to crate a random flow
        Shuffle(questions);
    }
    return [quizAttempt.id, questions];
}

//Shuffles array in place. ES6 version
export const Shuffle = (a) => {
    for (let i = a.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
}

//Takes the DOB string and returns age from there
const getAgeFromDob = (dob) => {
    let thisYear = new Date().getFullYear();
    let birthYear = new Date(dob).getFullYear();
    return thisYear - birthYear;
}

export const GetImageDownloadUrl = async (url) => {
    return await getDownloadURL(ref(storage, url));
}

//Downloads the image cleanly
function getImage(url) {
    return new Promise(function (resolve, reject) {
        let img = new Image()
        img.onload = function () {
            resolve(url)
        }
        img.onerror = function () {
            reject(url)
        }
        img.src = url
    })
}

//load images of the questions
export const loadImages = async (quizQues, startIndex, endIndex) => {
    let quesImages = [];
    let updatedQues = [];

    // run for questions from startIndex to endIndex of the quiz
    for (let currentIndex = startIndex; currentIndex < endIndex; currentIndex++) {

        const question = quizQues[currentIndex];

        // if the title has image and url is not empty
        if (question.titleimage) {
            // get the image url and replace the value of title image in actual object with download url
            await GetImageDownloadUrl(question.titleimage).then((r) => {
                question.titleimage = r;
                quesImages.push(r);
            });
        }

        // if the question type is of MCSAWI or MCMAWI i.e. questions have images in options
        if ((question.questiontype === '7fkHXSIKyOtbooiP6jIn') || (question.questiontype === 'ImoEwlhUHgRElqRR6LQb')) {
            // run for all the options
            for (let i = 0; i < question.options.length; i++) {
                let opt = question.options[i];
                // get the download url of the option image
                await GetImageDownloadUrl(opt.Key).then((r) => {
                    question.options[i] = {Key: r, Value: opt.Value};
                    quesImages.push(r);
                });
            }
        }
        // push the updated question into updatedQues array
        updatedQues.push(question);
    }
    // load all the images of quesImages array
    await quesImages.forEach(image => {
        getImage(image).then(function (successUrl) {
            // console.log("Image loaded : " + successUrl);
        }).catch(function (errorUrl) {
            console.log("Error loading image : " + errorUrl);
        })
    });

    // splice function delete the array values from startIndex to endIndex (excluding endIndex ) and add the values of updateQues in that position
    quizQues.splice(startIndex, endIndex, updatedQues);

    // flat function remove the outer array as : [1, 2, [3, 4]] :--- [1, 2, 3, 4] after applying flat function
    return (quizQues.flat());
}

/**
 * Generates a random six digit OTP number
 */
function generateOTPNumber() {
    return Math.floor(100000 + Math.random() * 900000);
}