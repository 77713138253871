import React from 'react';
import WhatsAppIcon from "../../assets/WhatsApp_Icon.png";
import FacebookIcon from "../../assets/FacebookIcon.png";
import {FacebookShareButton, WhatsappShareButton} from "react-share";
import GetAppIcon from '@material-ui/icons/GetApp';
import {userBatchStyle} from '../../screens/outcome/QuizScoreStyle';
import {SCREEN_WIDTHS, widthValues} from "../../utils/common";
import PropTypes from "prop-types";
import {withWidth} from "@material-ui/core";

function UserBadgeInfoAndSharing(props) {

    const classes = userBatchStyle();

    const isTablet=((props.width === SCREEN_WIDTHS.SM)||(props.width === SCREEN_WIDTHS.MD));

    const UserBadgeInfoToShare = " Hi There, I am " + props.UserBadgeInfo.title + ", on iTinker's FRS Assessment." +
        " Get your own badge by clicking here - frs.itinker.io \n\n";

    const download = e => {
        const xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = () => {};
        xhr.open('GET', e.target.href);
        xhr.send();
    };

    const RenderTitleAndBadge = () => {
        return(
            <React.Fragment>
                <h3 className={classes.badgeTitleStyle}>{props.UserBadgeInfo.title}</h3>
                <img id={"myimg"} className={classes.badgeImage} src={props.UserBadgeInfo.badgeurl} alt={"Badge"}/>
            </React.Fragment>
        )
    };

    const RenderBadgeInfoAndSharing = () => {
        return(
            <React.Fragment>
                <p className={classes.userBadgeText}>{props.UserBadgeInfo.badgetext}</p>

                <p className={classes.shareWithFriendsPara}>
                    <b className={classes.shareWithFriendsText}>Share With Friends</b>
                </p>

                <div className={classes.linksOuterDiv}>
                    <a href={props.UserBadgeInfo.badgeurl}
                       download
                       target="_blank"
                       rel="noopener noreferrer"
                       onClick={e => download(e)}>
                        <GetAppIcon className={classes.getAppIconStyle}/>
                    </a>

                    <WhatsappShareButton title={UserBadgeInfoToShare} url={props.UserBadgeInfo.badgeurl}>
                        <img className={classes.iconStyle} src={WhatsAppIcon} alt="logo"/>
                    </WhatsappShareButton>
                    <FacebookShareButton url={props.UserBadgeInfo.badgeurl} quote={UserBadgeInfoToShare}>
                        <img className={classes.iconStyle} src={FacebookIcon} alt="logo"/>
                    </FacebookShareButton>
                </div>
            </React.Fragment>
        )
    };

    if(isTablet){
        return (
            <div className={classes.outerDivStyle}>
                <div className={classes.col}><RenderTitleAndBadge /></div>
                <div className={classes.col}><RenderBadgeInfoAndSharing/></div>
            </div>
        )
    }
    else{
        return (
            <div className={classes.outerDivStyle}>
                <RenderTitleAndBadge/>
                <RenderBadgeInfoAndSharing/>
            </div>
        )
    }
}
UserBadgeInfoAndSharing.propTypes={
    width: PropTypes.oneOf(widthValues),
}
export default withWidth()((UserBadgeInfoAndSharing));
