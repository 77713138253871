import React, {useEffect, useMemo, useState} from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {CalculateRelativeScore} from "./MCSAWDI_Utils";
import {
    handleClassOfWrittenOption,
    handleRadioButtonOptionStyle,
    optionStyles as useStyles
} from "../questionTypesStyles";
import PropTypes from "prop-types";
import {widthValues} from "../../../utils/common";
import {withWidth} from "@material-ui/core";

//Multiple Choice Single Answer Type Quiz
function MCSAWDI(params) {
    const classes = useStyles();
    const [CheckedValue, setCheckedValue] = useState(' ');
    const ques = params.question;

    const quesOptions = useMemo(() => {
        let opt = [];
        for (let i=0; i < ques.options.length; i++) {
            let option = ques.options[i];
            // get the download url of the option image
            opt.push(option.Key);
        }
        return opt;
    }, [ques]);

    useEffect(() => {
        if(!params.isMobile){
            const maxHeight = Math.max(
                document.getElementById('option1').clientHeight,
                document.getElementById('option2').clientHeight,
                document.getElementById('option3').clientHeight,
                document.getElementById('option4').clientHeight,
            ) + 5;

            document.getElementById('option1').style.height = maxHeight.toString().concat("px");
            document.getElementById('option2').style.height = maxHeight.toString().concat("px");
            document.getElementById('option3').style.height = maxHeight.toString().concat("px");
            document.getElementById('option4').style.height = maxHeight.toString().concat("px");
        }

    }, [CheckedValue]);

    //Handles the event whenever another option is selected
    const handleChange = (event) => {
        params.setResponse(event.target.value);
        setCheckedValue(event.target.value);
        params.setRelativeScore(CalculateRelativeScore(ques, event.target.value));
    };

    const RenderOption = (params) => {
        return(
            <div className={classes.outerDiv}>
                <FormControlLabel id={params.optionId}
                                  className={handleClassOfWrittenOption(params.isOptionOnRight, params.value === CheckedValue)}
                                  value={params.value}
                                  label={<p className={classes.quesTitleStyle}>{params.value}</p>}
                                  control={<Radio onChange={handleChange} className={handleRadioButtonOptionStyle(CheckedValue,params.value )} />}
                />
            </div>
        )
    }
    const CurrentQuestionOptions = () => {
        return (
            <React.Fragment>
                <div className={classes.twoOptionsDiv} >
                    <RenderOption optionId={"option1"} value={quesOptions[0]} isOptionOnRight={false}/>
                    <RenderOption optionId={"option2"} value={quesOptions[1]} isOptionOnRight={true}/>
                </div>
                <div className={classes.twoOptionsDiv} >
                    <RenderOption optionId={"option3"} value={quesOptions[2]} isOptionOnRight={false}/>
                    <RenderOption optionId={"option4"} value={quesOptions[3]} isOptionOnRight={true}/>
                </div>
            </React.Fragment>
        )
    }

    return (
        <RadioGroup className={ques.titleimage ? "" : classes.OuterOptionsDiv}>
            <CurrentQuestionOptions/>
        </RadioGroup>
    )
};
MCSAWDI.propTypes={
    width: PropTypes.oneOf(widthValues),
}
export default withWidth()(React.memo(MCSAWDI));