import React, {useEffect, useMemo} from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {CalculateRelativeScore} from "./MCSA_Utils";
import {optionStyles as useStyles, handleRadioButtonWithImageOptionStyle} from '../questionTypesStyles';
import {handleWidthOfImageOptions} from "../../../screens/quiz/quiz_utils";

//Multiple Choice Single Answer With Images Type Quiz
function MCSAWI(params) {
    const classes = useStyles();
    const [CheckedValue, setCheckedValue] = React.useState(' ');
    const ques = params.question;
    const quesTitleHasImage = params.quesTitleHasImage;
    const [isPortrait, setIsPortrait] = React.useState(false);

    const quesOptions = useMemo(() => {
        let opt = [];
        for (let i=0; i < ques.options.length; i++) {
            let option = ques.options[i];
            // get the download url of the option image
            opt.push(option.Key);
        }
        return opt;
    }, [ques]);
    const imageUrls = quesOptions;

    useEffect(async()=>{
        setIsPortrait(await handleWidthOfImageOptions(quesOptions));
    },[])


    //Handles the event whenever another option is selected
    const handleChange = (event) => {
        params.setResponse(event.target.value);
        setCheckedValue(event.target.value);
        params.setRelativeScore(CalculateRelativeScore(ques, event.target.value));
    };

    const RenderOption = (params) => {
        const classNames = handleRadioButtonWithImageOptionStyle(CheckedValue===params.optionValue, params.isOptionOnRight, isPortrait, quesTitleHasImage);
        return (
            <FormControlLabel
                className={classNames.optionCls}
                value={params.optionValue}
                control={<Radio onChange={handleChange} className={classNames.radioBtn}/>}
                label={<img src={params.optionImage} alt={"option"} className={classNames.imageLabel} />}
            />
        )
    }

    const CurrentQuestionOptions = () => {
        return (
            <React.Fragment>
                <div>
                    <RenderOption optionValue={quesOptions[0]} optionImage={imageUrls[0]} isOptionOnRight={false}/>
                    <RenderOption optionValue={quesOptions[1]} optionImage={imageUrls[1]} isOptionOnRight={true}/>
                </div>
                <div>
                    <RenderOption optionValue={quesOptions[2]} optionImage={imageUrls[2]} isOptionOnRight={false}/>
                    <RenderOption optionValue={quesOptions[3]} optionImage={imageUrls[3]} isOptionOnRight={true}/>
                </div>
            </React.Fragment>
        )
    }

    return (
        <RadioGroup className={
            (isPortrait && !quesTitleHasImage) ? classes.portraitOuterDiv : classes.imageOptionsOuterDiv
        }>
            <CurrentQuestionOptions/>
        </RadioGroup> )
};
export default (React.memo(MCSAWI));