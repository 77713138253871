import React from 'react';

// Creating the context object and passing the default values.
const authContext = React.createContext({
    user: null, setUser: (user) => {},
    userQuizAttempts: null, setUserQuizAttempts: (userQuizAttempts) => {},
    isReferralEditable: false, setIsReferralEditable: (isReferralEditable) => {}
});

export default authContext;
