import {createMuiTheme, makeStyles} from "@material-ui/core/styles";

export const theme = createMuiTheme({
    overrides: {
        MuiRating: {
            sizeLarge: {
                fontSize: "3.875rem",
            },
        },
    },
});

export const FeedBackStyles = makeStyles((theme) => ({

    background: {
        height: "100vh",
        [theme.breakpoints.up('lg')]:{
            backgroundImage: 'linear-gradient(to bottom, #fbb888 , #fd9172)',
            display: "flex",
        },
        [theme.breakpoints.between('sm', 'md')]:{
            backgroundImage: 'linear-gradient(to top, #fbb888, #fd9172), linear-gradient(to bottom, #c4c4c4, #c4c4c4)',
            display: "inline-block", width: "100vw",
        },
        [theme.breakpoints.down('xs')]:{
            backgroundImage: 'linear-gradient(to bottom, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), ' +
                'linear-gradient(to top, #fbb888, #fd9172), linear-gradient(to bottom, #c4c4c4, #c4c4c4);',
            display: "inline-block", width: "100vw",
        },
    },
    cloud: {
        position: "absolute",
        [theme.breakpoints.up('lg')]:{
            width: "130px",
            height: "60px",
            top: "10vh",
            left: "1vw",
        },
        [theme.breakpoints.down('sm')]:{
            width: "70px",
            height: "35px",
            top: "5vh",
            left: "0vw",
        },
    },
    cloudMob: {
        width: "70px",
        height: "35px",
        position: "absolute",
        top: "5vh",
        left: "0vw",
    },

    rocket: {
        width: "200px",
        position: "absolute",
        bottom: "0vh",
        left: "-1vw",
    },

    phone: {
        [theme.breakpoints.up('lg')]:{
            width: "35vw",
            position: "absolute",
            right: "51vw",
        },
        [theme.breakpoints.between('sm', 'md')]:{
            width: "45vw",
            alignItems: "center",
            margin: "3vh auto",
            display: "block",
        },
        [theme.breakpoints.down('xs')]:{
            width: "75vw",
            alignItems: "center",
            margin: "3vh auto",
            display: "block",
        }
    },
    archArrow: {
        width: "100px",
        height: "80px",
        [theme.breakpoints.up('lg')]:{
            marginTop: "4vh",
        },
        [theme.breakpoints.between('sm', 'md')]:{
            position: 'absolute',
            right: '5vw',
            top:"40vh",
        },
        [theme.breakpoints.down('xs')]:{
            position: 'absolute',
            bottom: '1vh',
        },
    },

    halfCloud: {
        position: "absolute",
        right: "0vw",
        [theme.breakpoints.up('lg')]:{
            width: "90px",
            bottom: "5vh",
        },
        [theme.breakpoints.between('sm', 'md')]:{
            width: "70px",
            top: "25vh",
        },
        [theme.breakpoints.down('xs')]:{
            width: "70px",
            bottom: "40vh",
        },
    },
    rateYourAssessmentText: {
        fontWeight: "bold",
        [theme.breakpoints.up('sm')]:{fontSize: "30px", },
        [theme.breakpoints.down('sm')]:{
            fontSize: "25px", textAlign: "center"
        }
    },
    leftSide: {display: "flex", flexDirection: "column", width: "60%", justifyContent: "center", alignItems: "center"},

    rightSide: {
        display: "flex", flexDirection: "column",justifyContent: "center", alignItems: "center",height:"50vh",
        [theme.breakpoints.up('lg')]:{
            width: "40vw",
            backgroundColor: "white",
            borderRadius: 30,
            padding: "50px", alignSelf: "center",
            justifySelf: "center", height: "80vh",
        },
    },
    addAnything: {
        [theme.breakpoints.up('sm')]:{
            padding: "5px",
            margin: "25px 10px 60px",
            width: "70%",
        },
        [theme.breakpoints.down('sm')]:{
            width: "90%", margin: "15px 10px 40px 10px",backgroundColor: "white !important",
        }
    },
    submitBtn: {
        border: "none",
        padding: "7px 32px 7px 32px",
        backgroundColor: "#fdd4a9",
        borderRadius: 20,
        color: "#484646",
        fontSize: "20px",
        fontWeight: "500"
    },
    ratingStyle: {
        [theme.breakpoints.down('sm')]:{
            display: "flex", justifyContent: "center", fontSize: "4rem"
        },
    },
}));
