import React from 'react';
import FullCloud from "../../assets/full_cloud.png";
import HalfCloud from "../../assets/half_cloud.png";
import FRS_Logo from "../../assets/frs_logo.png";
import CompanyLogo from "../../assets/itinker_logo.png";
import ArrowArchAsset from "../../assets/arrow_arch_asset.png";
import {SCREEN_WIDTHS, widthValues} from "../../utils/common";
import PropTypes from "prop-types";
import {withWidth} from "@material-ui/core";
import {styles as useStyles} from "../../screens/home/IntroScreenStyle";

const RenderFRSLogo = (props) => {
    const classes = useStyles();
    const isMobile=(props.width === SCREEN_WIDTHS.XS);
    const isTablet=((props.width === SCREEN_WIDTHS.SM)||(props.width === SCREEN_WIDTHS.MD));

    const RenderImages = () => {
        return (
            <React.Fragment>
                {/* render half cloud asset */}
                <div className={classes.halfCloudOuterDiv}>
                    <img src={isMobile ? FullCloud : HalfCloud} alt="HalfCloud" className={classes.halfCloudStyle}/>
                </div>

                {/* render FRS Logo */}
                <div className={classes.FRSlogoOuterDiv}>
                    <img src={FRS_Logo} alt="Future Readiness Score" className={classes.FRSLogoStyle}/>
                </div>
            </React.Fragment>
        )
    }

    if (isMobile)
        return ( <div className={classes.backgroundImageStyle}><RenderImages/></div> )
    if (isTablet){
        return (
            <>
                <div className={classes.tabletTopColor} />
                <div className={classes.backgroundImageStyle}>
                    <RenderImages/>
                    <div className={classes.column}>
                        {/* render company logo */}
                        <img src={CompanyLogo} alt="company logo" className={classes.companyLogoStyle}/>
                        {/* render Arrow Arch Asset asset */}
                        <div className={classes.row}>
                            <img src={ArrowArchAsset} alt="Future Readiness Score" className={classes.ArrowArchAssetStyle}/>
                        </div>
                    </div>
                </div>
            </>
        )
    }
    else
        return (
            <div className={classes.backgroundImageStyle}>
                <RenderImages/>
                <div className={classes.column}>
                    {/* render company logo */}
                    <div className={classes.row}>
                        <img src={CompanyLogo} alt="company logo" className={classes.companyLogoStyle}/>
                    </div>
                    {/* render Arrow Arch Asset asset */}
                    <div className={classes.row}>
                        <img src={ArrowArchAsset} alt="Future Readiness Score" className={classes.ArrowArchAssetStyle}/>
                    </div>
                </div>
            </div>
        )
};

RenderFRSLogo.propTypes={
    width: PropTypes.oneOf(widthValues),
}
export default withWidth()((RenderFRSLogo));