import {makeStyles} from "@material-ui/core";
import HeaderImage from '../../assets/WelcomeScreenTopVector.svg';

export const marksMeterStyle = makeStyles( theme => ({
    outerDiv: {
        [theme.breakpoints.up('lg')]:{
            padding: "10px 10px 0 10px",
        },
        [theme.breakpoints.between('sm','md')]:{
            padding: "20px 10px 0 10px",
        },
        [theme.breakpoints.down('xs')]:{
            paddingTop: "5vh", width: "100%"
        },
    },
    ScoreStyle: {
        flexGrow: "0",
        fontFamily: "'Open Sans', sans-serif",
        textAlign: "center",
        paddingLeft: "10px",
        color: "#000000",
    },
    chartAvgStyle:{width: "23px", marginRight: "20px"},
    yourScoreText: {fontSize: "15px", paddingTop: "15px", fontWeight:"bold", marginBottom: "10px"},
    scoreStyle:{
        fontSize: "25px",
        display: "block",
        padding: "0px 8px",
        color: "#f49313"
    },
    timeTakenOuterDiv: {margin: "auto", marginBottom: "20px"},
    timeTakenStyle: {fontSize: "15px"},
}));

export const skillsBlockStyle = makeStyles(theme => ({
    row: {display: "flex", flexWrap: "wrap",},
    outerDiv: {
        width: "100%",display: "flex", flexWrap: "wrap",
        [theme.breakpoints.down('md')]:{
            margin: "20px 0px 0px 0px",
        },
    },
    skillTitle: {
        fontFamily: "'Open Sans', sans-serif",
        fontWeight: "bold",
        textAlign: "center",
        margin: "auto auto 10px",
        color: "#000000",
    },
    skillText: {fontSize: "11px"},
    skillImage: {height: "55px", width: "60px",},
    skillDiv: {flexBasis: "0", flexGrow: "1", maxWidth: "100%",height: "max-content", margin: "2px 6px",},
    skillsStyle: {
        margin: "auto",
        [theme.breakpoints.up('lg')]:{
            width: "80%",
            padding: "10px",
            borderRadius: "50px",
            backgroundColor: "#ffede0",
            boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.25)",
        },
        [theme.breakpoints.between('sm','md')]:{
            width: "100%",display: "flex", flexWrap: "wrap",
        },
        [theme.breakpoints.down('xs')]:{
            width: "90%",
            marginBottom: "20px", marginTop: "20px",display: "flex", flexWrap: "wrap",
        },
    },
    skillsStyleInnerRow: {
        display:"flex",
        [theme.breakpoints.down('xs')]:{
            width: "90%", margin: "auto", marginBottom: "20px",display: "flex", flexWrap: "wrap",
        },
        [theme.breakpoints.between('sm','md')]:{
            width: "90%", margin: "auto", marginTop: "20px",display: "flex", flexWrap: "wrap",
        },
    },
}));

export const DetailedAnalysisStyle = makeStyles( theme=> ({
    blurDiv: {filter: "blur(8px)", webkitFilter: "blur(8px)",margin: "auto"},
    centerImgStyle: {
        width: "325px", alignItems: "center", margin: "auto",
        [theme.breakpoints.only('md')]:{width: "450px",}
    },
    imgStyle: {
        width: "185px", flexGrow: "0", borderRadius: "15px",margin: "auto",
        [theme.breakpoints.down('xs')]:{width: "45%",},
    },
    BlurFrontStyle: {
        fontWeight: "bold",
        position: "absolute",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        zIndex: "2",
        textAlign: "center",
        alignItems: "center",
        [theme.breakpoints.only('sm')]:{transform: "translate(8%, -150%)",},
        [theme.breakpoints.only('md')]:{transform: "translate(27%, -150%)",},
        [theme.breakpoints.down('xs')]:{transform: "translate(0%, -132%)",},
    },
    paraStyle: {fontSize: "18px", fontWeight: "400", margin: "auto"},
    appDownloadOuterDiv: {padding: "20px"},
    appDownloadLinkStyle: {width: "300px", borderRadius: "10px", flexBasis: "0", flexGrow: "1", maxWidth: "100%"},
    cashBackParaStyle: {fontSize: "18px", fontWeight: "400", margin: "auto"},
}));

export const userBatchStyle = makeStyles( theme => ({
    iconStyle: {
        width: "60px",
        height: "60px",
        flexGrow: "0",
        margin: "0 6px 0 0",
        padding: "7px",
        backgroundColor: "#ffdebc",
        borderRadius: "40px",
    },
    getAppIconStyle : {
        backgroundColor: "#ffdebc", borderRadius: 50, margin: "0 6 0 0",
        padding: "7", height: 60, width: 60
    },
    linksOuterDiv: {alignItems: "center", marginTop: "5px",},
    shareWithFriendsText: {textAlign: "center",},
    shareWithFriendsPara: {marginTop: 30},
    userBadgeText: {textAlign: "center", margin: "auto"},
    badgeImage: {
        height: "300px", margin: "auto",marginBottom: 30
    },
    badgeTitleStyle: {
        color: "#174673",
        fontWeight: "bold",
        textTransform: "uppercase", marginBottom: 10
    },
    outerDivStyle: {
        [theme.breakpoints.up('lg')]:{
            justifyContent: "space-around",display: "flex", flexDirection: "column",
        },
        [theme.breakpoints.between('sm','md')]:{
            alignItems: "center",display: "flex", flexWrap: "wrap",
        },
        [theme.breakpoints.down('xs')]:{
            alignItems: "center",display: "flex", flexDirection: "column",
        },
    },
    col:{
        flexBasis: "0",
        flexGrow: "1",
        maxWidth: "100%",
        margin: "3vh auto 2vh",
    },
    noBadgeTitleStyle: {
        height: "fit-content",
        fontFamily: "OpenSans",
        fontSize: "35px",
        fontWeight: "bold",
        textAlign: "center",
        color: "#174673",
        [theme.breakpoints.up('lg')]:{
            width: "32vw",
            marginTop: "10vh",
        },
        [theme.breakpoints.down('xs')]:{
            width: "95vw",
            marginTop: "2vh",
        },
    },
    noBadgeAssetStyle: {
        alignItems: "center",
        margin: "auto",
        [theme.breakpoints.up('lg')]:{
            height: "40vh",
            width: "20vw",
        },
        [theme.breakpoints.between('sm','md')]:{
            height: "270px",
        },
        [theme.breakpoints.down('xs')]:{
            height: "35vh",
            width: "70vw",
            marginBottom: "3vh",
        },
    },
    noBadgeMessageStyle:{
        [theme.breakpoints.only('md')]:{fontSize: "30px", marginRight: "30px",},
    },
}));

export const loadingScoreStyle = makeStyles(theme => ({
    HeaderStyle: {
        backgroundImage: `url(${HeaderImage})`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        height: "60vh",
    },
    divStyle: {
        height: "100vh",
        display: "flex",
        flexDirection: "Column",
        justifyContent: "center",
        alignItems: "center"
    },
    textStyle: {padding: "10px"},
    outerDiv: {
        textAlign: "center", height: "100vh",width: "100%",
    },
    userSideStyleMobile: {
        boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.25)",
        backgroundColor: "#ffede0",
        [theme.breakpoints.down('xs')]:{
            width: "96vw",
            margin: "1vh 2vw",
            padding: "14px 10px",
            borderRadius: "50px",
        },
        [theme.breakpoints.between('sm','md')]:{
            width: "100vw",
            borderRadius: "30px 30px 50px 50px",
        },
    },
    detailedAnalysisOuterDiv: {width: "100%"},
    firstColStyle: {
        alignItems: "center",
        justifyContent: "center",
        display: "inline-flex",
        flexDirection: "column",
        position: "absolute",
        top: "0vh",
    },
    userStyle: {width: "36vw", left: "30vw",},
    userSideStyle: {
        flexGrow: "0",
        margin: "1vh 20px",
        height: "98vh",
        padding: "13px 9px 14px 10px",
        borderRadius: "50px",
        boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.25)",
        backgroundColor: "#ffede0",
    },
    firstRowStyle: {left: "0vw", width: "30vw",minHeight: "100vh"},
    row: {display: "flex", flexWrap: "wrap",},
    skillsAndMarksMeterOuterDiv:{
        display: "flex", flexWrap: "wrap",
        [theme.breakpoints.only('md')]:{margin: "5vh",},
    },
    col:{flexBasis: "0", flexGrow: "1", maxWidth: "100%",},
    appDownload: {
        width: "33vw", right: "1.5vw", height: "99vh",
    },

}));