import {styles as useStyles} from "../../screens/home/IntroScreenStyle";
import React, {useContext, useEffect, useState} from "react";
import {assignStyleToSignInBtn} from "../../screens/home/IntroScreenStyle";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import CircleChecked from "@material-ui/icons/CheckCircleOutline";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import 'react-phone-input-2/lib/material.css';
import OtpInput from 'react-otp-input';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import {SendOTP, LoginUser, AddUserData, GetUserQuizAttempts, FirebaseSetup} from "../../services/Database";
import {Constants} from "../../utils/Constants";
import {UserModelFromJson} from "../../models/UserModels";
import TimerIcon from "@material-ui/icons/Timer";
import Countdown from "react-countdown";
import AuthContext from '../../utils/AuthContext';
import {getAuth, signInAnonymously} from "firebase/auth";
import {Analytics} from "../../services/Analytics";
import Cookies from "universal-cookie";


function RenderMobileOtpLogin() {

    const [phone, setPhone] = React.useState(0);
    const [countryCode, setCountryCode] = React.useState("+91");
    const [sendOtp, setSendOtp] = React.useState(false);
    const [otp, setOtp] = React.useState('');
    const [reqOTP, setReqOTP] = React.useState(111111);
    const [existingUser, setExistingUser] = React.useState(false);
    const [existingFRSUser, setExistingFRSUser] = React.useState(false);
    const [otpSent, setOtpSent] = React.useState(true);
    const [authError, setAuthError] = React.useState("");
    const [allowedToReqOTP, setAllowedToReqOTP] = React.useState(true);
    //For the checkbox that user needs to click to move to the next step
    const [checked, setChecked] = React.useState(false);
    let auth = useContext(AuthContext);


    //Changes the value of the checked variable
    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    //Updates country code and mobile number as per input from user
    const handleMobileChange = (value, data) => {
        setPhone(value.slice(data.dialCode.length));
        setCountryCode("+" + data.dialCode);
    }

    // handles the sendOtp value
    const handleSendOtp = async () => {
        if (allowedToReqOTP) {
            setAllowedToReqOTP(false);
            Analytics(Constants.screen_login, Constants.track_send_otp, Constants.track_click).then();
            let outcome = await SendOTP(countryCode, phone);
            setReqOTP(outcome[0]);
            setExistingUser(outcome[1]);
            setExistingFRSUser(outcome[2]);
            setOtpSent(outcome[3]);
            setSendOtp(outcome[3]);
            setAllowedToReqOTP(true);
        }
    };

    // handle login of the user after entering the OTP by the user
    const handleLogin = async () => {
        Analytics(Constants.screen_login, Constants.track_login_button, Constants.track_click)
        if (parseInt(otp) !== reqOTP) {
            setAuthError("Wrong OTP. Please Try Again");
        } else {
            let res;
            if (existingFRSUser || existingUser) {
                res = await LoginUser(countryCode, phone)
            } else {
                res = await AddUserData(countryCode, phone)
            }
            if (res !== "" && res !== null) {
                setAuthError("");
                let cookies = new Cookies();
                cookies.set(Constants.auth_token, res.token, {
                    path: "/",
                    secure: Constants.secureCookies,
                    domain: Constants.domain
                });
                let user = new UserModelFromJson(res.user);
                let userQuizAttempts = await GetUserQuizAttempts(user.uid);
                auth.setUser(user);
                auth.setUserQuizAttempts(userQuizAttempts);
                //Sign in anonymously to Firebase for accessing the storage
                await signInAnonymously(getAuth(FirebaseSetup))
                if (user.frsrefcode === undefined || user.frsrefcode === "") {
                    auth.setIsReferralEditable(true);
                }
            } else {
                setAuthError("Error Authenticating your phone number. Try with a different one.");
            }
        }
    }

    if (sendOtp) {
        return (<RenderEnterOtp mobileNo={phone} countryCode={countryCode} otp={otp} setOtp={setOtp}
                                authError={authError}
                                setSendOtp={setSendOtp} handleLogin={handleLogin} handleSendOtp={handleSendOtp}/>)
    } else {
        return (
            <RenderSendOtp
                mobileNo={phone} handleChange={handleChange} handleSendOtp={handleSendOtp} otpSent={otpSent}
                checked={checked} countryCode={countryCode} handleMobileChange={handleMobileChange}
            />
        )
    }
}

export default (RenderMobileOtpLogin);

// render components for submission of otp and login
function RenderEnterOtp(params) {
    const {mobileNo, countryCode, otp, setOtp, setSendOtp, handleLogin, handleSendOtp, authError} = params;
    const classes = useStyles();
    const [timeAllotted, setTimeAllotted] = useState(60);
    const [timeSpent, setTimeSpent] = useState(0);
    const [resendAllowed, setResendAllowed] = useState(false);

    //Update values as necessary
    useEffect(() => {

        // Setup the timer function
        const timerId = setInterval(() => {
            setTimeSpent((t) => t + 1);
        }, 1000);

        return () => {
            clearInterval(timerId);
        }
    }, [timeAllotted]);


    //Handle resend otp click
    function handleResendClick() {
        setResendAllowed(false);
        setTimeAllotted(60);
        handleSendOtp();
    }

    // Renderer for countdown timer
    const countdownRenderer = (time) => {
        return (
            <div className={classes.timerStyle}>
                <TimerIcon/>
                <button className={classes.timerBtnStyle} onClick={handleResendClick} disabled={!resendAllowed}>
                    <b className={resendAllowed ? classes.resend_style : ""}>Resend
                        OTP{resendAllowed ? "" : " in " + time.seconds + " seconds"}</b>
                </button>
            </div>
        )
    }

    // Handles the triggers when timeout happens
    const handleTimeout = () => {
        setResendAllowed(true);
    }

    return (
        <div>
            <span className={classes.otp_verification_style}>OTP Verification</span>
            <span>Enter the OTP sent to <span className={classes.mobileNoStyle}>{countryCode + mobileNo}</span></span>
            <EditOutlinedIcon onClick={() => setSendOtp(false)}/>
            <OtpInput
                containerStyle={classes.otpInput}
                value={otp}
                onChange={setOtp}
                numInputs={6}
                shouldAutoFocus={true}
            />
            <Countdown
                date={new Date().getTime() + ((timeAllotted - timeSpent) * 1001)}
                onComplete={handleTimeout}
                renderer={countdownRenderer}
            />
            <button className={assignStyleToSignInBtn(true)} id={"login"} onClick={handleLogin}>
                Login
            </button>
            {authError !== "" ?
                <p>{authError}</p>
                :
                <></>
            }
        </div>
    )
}

// having the components for mobile number submission to send otp with terms and conditions acceptation
function RenderSendOtp(params) {
    const classes = useStyles();
    const {handleMobileChange, handleChange, handleSendOtp, checked, otpSent} = params;
    return (
        <div className={classes.buttonDiv}>
            <PhoneInput
                containerClass={classes.phoneInputStyle}
                country={"in"}
                specialLabel={'Enter Mobile Number'}
                onChange={handleMobileChange}
                placeholder={"+91 97797-70446"}
                inputProps={{
                    name: 'Mobile',
                    required: true,
                    autoFocus: true
                }}
            />
            <AcceptTermsAndConditions checked={checked} handleChange={handleChange}/>
            <button className={assignStyleToSignInBtn(checked)} id={"send_otp"} disabled={!checked}
                    onClick={handleSendOtp}>
                Send OTP
            </button>
            {!otpSent ?
                <p>OTP Not Sent. Please Try Again</p>
                :
                <></>
            }
        </div>
    )
}

// displays the checkbox for accepting terms and conditions
const AcceptTermsAndConditions = (params) => {
    const classes = useStyles();
    return (
        <React.Fragment>
            <FormControlLabel className={classes.acceptTAC}
                              control={
                                  <Checkbox
                                      icon={<CircleUnchecked/>}
                                      checkedIcon={<CircleChecked/>}
                                      checked={params.checked}
                                      onChange={params.handleChange}
                                      name="checked"
                                      color="primary"
                                  />
                              }
                              label={
                                  <span className={classes.termsAndConditionsLabelStyle}>
                                    I comply with FRS <a href={"/terms"} rel="noreferrer">terms & conditions</a> and am ready to
                                    take the assessment honestly and without any external help.
                                  </span>
                              }
            />
        </React.Fragment>
    )
}
