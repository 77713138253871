import React from 'react';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import {Route, BrowserRouter as Router, Switch} from 'react-router-dom';
import TermsAndConditions from "./components/authentication/TermsAndConditions";
import Home from "./screens/Home";

require('firebase/auth')

// the main function which handles all the renderings on the screen
export default function App() {
    return (
        <Routing/>
    );
}

function Routing(){
    return(
        <Router>
            <Switch>
                <Route exact path="/terms">
                    <TermsAndConditions/>
                </Route>
                <Route exact path="/">
                    <Home/>
                </Route>
            </Switch>
        </Router>
    )
}