import {logEvent} from "@firebase/analytics";
import {analytics} from "./Database";
import {Constants} from "../utils/Constants";
import {setCurrentScreen} from "firebase/analytics";

// log event to the firebase analytics
export const Analytics = async(k, p, c, o, f, g, s) => {
    await logEvent(analytics, Constants.userEvent, {
        'k': k,
        'p': p,
        'c': c,
        'o': o || '',
        'f': f || '',
        'g': g || '',
        's': s || '',
        'timestamp': Date.now()
    });
}

// set screen name of the analytics
export const setAnalyticsScreen = async(screenName) => {
    await setCurrentScreen(analytics, screenName)
}