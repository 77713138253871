import React from "react";
import CompanyLogo from "../../assets/itinker_logo.png";
import RocketAsset from "../../assets/rocket_asset.png";
import FullCloud from "../../assets/full_cloud.png";
import {SCREEN_WIDTHS, widthValues} from "../../utils/common";
import PropTypes from "prop-types";
import {withWidth} from "@material-ui/core";
import {styles as useStyles} from "../../screens/home/IntroScreenStyle";
import RenderMobileOtpLogin from "../authentication/RenderMobileOtpLogin";


const RenderFRSInfo = (params) => {

    const classes = useStyles();
    const isMobile = (params.width === SCREEN_WIDTHS.XS);
    const isTablet = ((params.width === SCREEN_WIDTHS.SM) || (params.width === SCREEN_WIDTHS.MD));

    // return of the function
    if (isMobile)
        return (
            <div className={classes.FRSInfoRowStyle}>
                <div className={classes.ParaStyle}>
                    <img src={CompanyLogo} alt="company logo" className={classes.companyLogoStyle}/>
                    <RenderFrsInfoPara/>
                    <RenderMobileOtpLogin/>
                </div>
                <div className={classes.column}>
                    <img src={FullCloud} alt="Full Cloud" className={classes.cloudStyleInInfo}/>
                </div>
            </div>

        )
    if (isTablet) {
        return (
            <div className={classes.FRSInfoRowStyle}>
                {/* render rocket asset */}
                <img src={RocketAsset} alt="RocketAsset" className={classes.rocketAssetStyle}/>
                {/* render frs info para and buttons */}
                <div className={classes.ParaStyle}>
                    <RenderFrsInfoPara/>
                    <RenderMobileOtpLogin/>
                </div>
                {/* render half cloud asset */}
                <img src={FullCloud} alt="HalfCloud" className={classes.cloudStyleInInfo}/>
            </div>
        )
    } else
        return (
            <div className={classes.FRSInfoRowStyle}>
                {/* render rocket asset */}
                <div className={classes.rocketAssetOuterDiv}>
                    <img src={RocketAsset} alt="RocketAsset" className={classes.rocketAssetStyle}/>
                </div>
                {/* render frs info para and buttons */}
                <div className={classes.ParaStyle}>
                    <RenderFrsInfoPara/>
                    <RenderMobileOtpLogin/>
                </div>
                {/* render half cloud asset */}
                <div className={classes.column}>
                    <img src={FullCloud} alt="HalfCloud" className={classes.cloudStyleInInfo}/>
                </div>
            </div>
        )
};

RenderFRSInfo.propTypes = {
    width: PropTypes.oneOf(widthValues),
}
export default withWidth()((RenderFRSInfo));

const RenderFrsInfoPara = () => {
    const classes = useStyles();
    return (
        <React.Fragment>
            <h1 className={classes.FRSTitleTextStyle}>What is FRS&trade;? </h1>
            <p className={classes.frsTextStyle}>
                Future Readiness Score (FRS) assessment is a global, industry leading, AI based platform to
                intelligently gauge the future readiness level of your child. This is done via our Proprietary
                Assessment
                Algorithms, that take into account the various aspects of readiness, as recommended by the World
                Economic Forum.
            </p>
        </React.Fragment>
    )
}