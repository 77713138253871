import React, {useEffect} from 'react';
import RenderFRSLogo from '../../components/home/RenderFRSLogo';
import RenderFRSInfo from '../../components/home/RenderFRSInfo';
import { styles as useStyles } from "./IntroScreenStyle";
import {withWidth} from "@material-ui/core";
import {setAnalyticsScreen} from "../../services/Analytics";
import {Constants} from "../../utils/Constants";

function IntroScreen() {

    useEffect(() => {
        setAnalyticsScreen(Constants.screen_login)
    })

    // having style classes for the component
    const classes = useStyles();
    return (
        <div className={classes.introOuterDiv}>
            <RenderFRSLogo/>
            <RenderFRSInfo/>
        </div>
    )

}
export default withWidth()((IntroScreen));