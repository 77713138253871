
//Calculates the relative score of the chosen option
export const CalculateRelativeScore = (ques, selectedOptions) => {
    let maxScore = 0;
    let selectedScore = 0;
    for (let i=0; i < ques.options.length; i++) {
        let option = ques.options[i];
        let key = option.Key;
        let value = option.Value;
        if(selectedOptions.includes(key)) {
            selectedScore = selectedScore + parseFloat(value.toString());
        }
        //Don't add negative scoring to the Maximum score calculation
        maxScore = maxScore + (parseFloat(value.toString()) >= 0 ? parseFloat(value.toString()) : 0);
    }
    selectedScore = selectedScore < 0 ? 0 : selectedScore; //If selected score negative, set it to Zero
    maxScore = maxScore === 0 ? 1 : maxScore; //If maximum score zero, set it to One
    return (selectedScore/maxScore).toFixed(1).toString();
}

//Calculates response values of the user based on ticked options
export const CalculateResponseValues = (option1, option2, option3, option4, options) => {
    let response = [];
    if (option1) {
        response.push(options[0]);
    }
    if (option2) {
        response.push(options[1]);
    }
    if (option3) {
        response.push(options[2]);
    }
    if (option4) {
        response.push(options[3]);
    }
    return response;
}