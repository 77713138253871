import React from 'react';
import { styles as useStyles } from "./styles";

export default function TermsAndConditions(){
    const classes = useStyles();
    return(
        <>
            <div className={`${classes.termsHeaderStyle}`}>
                <div className={`${classes.termsBottomVectorStyle}`}>
                    <p className={classes.TAC_title}><b>Terms and Conditions</b></p>
                    <TACs/>
                </div>
            </div>
        </>
    )
}

function TACs(){
    const classes = useStyles();
    return(
        <div className={classes.termsAndConditionsBox}>
            <div className={classes.termsAndConditionsBoxContent}>
                <ol className={classes.TACs}>
                    <li>This assessment is meant for school children within the Age Group <b>10-18 only.</b>
                    </li>
                    <li>There will be a maximum of <b>40 questions in the assessment.</b>The questions are randomly
                        generated every time, based on your age and demography, meaning <b>no two assessments are
                            alike.</b>
                    </li>
                    <li>Each question in the assessment is timed individually, so total duration of your quiz will vary
                        every time you take it. Having said that, the estimated time to complete is <b>10-20
                            minutes,</b>
                        based on your speed.
                    </li>
                    <li>You need to complete the entire assessment in <b>one sitting only.</b> Any disruption,
                        intentional
                        or unintentional, will cause the attempt to be disqualified and you will have to start again.
                    </li>
                    <li>You can attempt the assessment <b>at max once every 24 hours.</b> In case you complete it, you
                        will
                        have to wait 15 days to take it again. In the meantime, please work on improving your skills.
                    </li>
                    <li>The starting and ending time of every assessment is recorded. In case your attempt takes longer
                        than
                        the sum of individual question times, it <b>will NOT be considered for the competition.</b>
                    </li>
                    <li>In case it is found out that you have taken any external help to complete your quiz, you will be
                        automatically <b>disqualified from the competition.</b>
                    </li>
                </ol>
            </div>
        </div>
    )
}