import React, {useEffect, useState} from 'react';
import Dashboard from "./dashboard/Dashboard";
import IntroScreen from "./home/IntroScreen";
import {CheckUserAuth, FirebaseSetup, GetUserQuizAttempts} from "../services/Database";
import {Constants} from "../utils/Constants";
import {UserModelFromJson} from "../models/UserModels";
import {Lightbox} from "react-modal-image";
import PopUpPosterMob from "../assets/mobile_frs.png";
import PopUpPosterTablet from "../assets/tablet_frs.png";
import PopUpPoster from "../assets/web_frs.png";
import AuthContext from '../utils/AuthContext';
import {SCREEN_WIDTHS, widthValues} from "../utils/common";
import PropTypes from "prop-types";
import {withWidth} from "@material-ui/core";
import {getAuth, signInAnonymously} from "firebase/auth";
import {Analytics, setAnalyticsScreen} from "../services/Analytics";
import Cookies from "universal-cookie";

function Home(props) {

    // having bool value for, is the current screen size is of mobile ('xs') or not
    const isMobile = (props.width === SCREEN_WIDTHS.XS);
    const isTablet = ((props.width === SCREEN_WIDTHS.SM) || (props.width === SCREEN_WIDTHS.MD));

    // user having values and setUser is used to set the value of user variable initialized to null
    const [user, setUser] = useState(null);
    const [userQuizAttempts, setUserQuizAttempts] = useState([]);
    const [showPosterPopUp, setShowPosterPopUp] = useState(false);
    const [isReferralEditable, setIsReferralEditable] = useState(false);

    // render every time the value of user changes and assign the value to variable user accordingly
    useEffect(() => {
        setAnalyticsScreen(Constants.screen_home).then()
        document.title = "iTinker - FRS Assessment";
        CheckUserAuth().then(async (res) => {
            if (res !== "" && res !== null) {
                let cookies = new Cookies();
                cookies.set(Constants.auth_token, res.token, {
                    path: "/",
                    secure: Constants.secureCookies,
                    domain: Constants.domain
                });
                let user = new UserModelFromJson(res.user);
                let userQuizAttempts = await GetUserQuizAttempts(user.uid);
                setUser(user);
                setUserQuizAttempts(userQuizAttempts);
                //Sign in anonymously to Firebase for accessing the storage
                await signInAnonymously(getAuth(FirebaseSetup))
                if (user.frsrefcode === undefined || user.frsrefcode === "") {
                    setIsReferralEditable(true);
                }
            } else {
                setShowPosterPopUp(true);
            }
        })
    }, []);

    const handlePopUpClose = () => {
        Analytics(Constants.popup_promotional, Constants.track_popup, Constants.track_close);
        setShowPosterPopUp(false)
    }

    const RenderPosterPopUp = () => {
        // show pop up if variable showPosterPopUp is true
        if (showPosterPopUp) {
            Analytics(Constants.popup_promotional, Constants.track_popup, Constants.track_view);
            return (
                <Lightbox
                    large={isMobile ? PopUpPosterMob : isTablet ? PopUpPosterTablet : PopUpPoster}
                    hideDownload={true}
                    hideZoom={true}
                    onClose={handlePopUpClose}
                />
            )
        } else return <></>
    };

    return (
        <AuthContext.Provider value={{
            user: user,
            setUser: setUser,
            userQuizAttempts: userQuizAttempts,
            setUserQuizAttempts: setUserQuizAttempts,
            isReferralEditable: isReferralEditable,
            setIsReferralEditable: setIsReferralEditable,
        }}>
            {/* show pop up of the poster when FRS is loaded*/}
            <RenderPosterPopUp/>
            {user != null && user && userQuizAttempts != null && userQuizAttempts ?
                <Dashboard/>
                :
                <IntroScreen/>
            }
        </AuthContext.Provider>
    )
}

Home.propTypes = {
    width: PropTypes.oneOf(widthValues),
}
export default withWidth()(Home);