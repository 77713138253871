import {createMuiTheme, makeStyles, withStyles} from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import HeaderImage from '../../assets/WelcomeScreenTopVector.svg';
import bottomVector from "../../assets/WelcomeScreenBottomVector.svg";
import bottomVectorMob from "../../assets/LoginPhoneVectorBottom.svg";

export const styles = makeStyles(theme=>({
    outermostQuizDiv: {textAlign: "center", height: "100vh", width: "100%",},
    quizHeaderStyle: {
        backgroundImage: `url(${HeaderImage})`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        [theme.breakpoints.up('lg')]:{
            height: "60vh",
        },
        [theme.breakpoints.between('sm', 'md')]:{
            height: "43vh",
        },
        [theme.breakpoints.down('xs')]:{
            height: "35vh",
        }
    },
    timerStyle:{
        [theme.breakpoints.up('lg')]:{padding: "20px 10px"},
        [theme.breakpoints.down('xs')]:{padding: "80px 10px 20px"},
        [theme.breakpoints.between('sm', 'md')]:{padding: "100px 10px 20px"},
    },
    circularProgressDiv:{position: "relative", top: "40%"},
    QuestionsLinearProgressStyle: {width: "70%"},
    quizButton: {
        display: "flex",
        width: "137px",
        height: "42px",
        flexGrow: "0",
        borderRadius: "25px",
        backgroundColor: "#fdd4a9",
        border: "none",
    },
    btnText:{
        textAlign: "center",
        margin: "auto",
        verticalAlign: "middle",
    },
    nextBtn: {
        padding: "10px 52px",
        margin: "10px auto",
        display: "flex",
        borderRadius: "25px"
    },
    submitBtn: {
        padding: "10px 40px",
        fontWeight: "bold",
        display: "flex",
        borderRadius: "25px",
        margin: "10px auto",
    },
    lowerDiv: {
        width: "98vw",
        margin: "20px auto",
    },
    writtenQuesStyle: {
        [theme.breakpoints.up('lg')]:{
            margin: "auto !important", alignItems: "center", justifyContent: "center",width: "75%",
        },
        [theme.breakpoints.between('sm','md')]:{
            margin: "auto !important",width: "80%",
        },
        [theme.breakpoints.down('xs')]:{
            margin: "auto !important",width: "90%",
        }
    },
    imageQuesStyle: {
        [theme.breakpoints.up('lg')]:{
            margin: "auto !important", alignItems: "center", justifyContent: "center",width: "75%",display: "contents",
        },
        [theme.breakpoints.between('sm','md')]:{
            margin: "auto !important",width: "80%",
        },
        [theme.breakpoints.down('xs')]:{
            margin: "auto !important",width: "90%",
        }
    },
    quesStyle: {
        flexGrow: "0",
        margin: "20px 0 20px",
        borderRadius: "15px",
        boxShadow: "0 2px 14px 0 rgba(0, 0, 0, 0.1)",
        alignItems: "center",
        textAlign: "center",
        backgroundColor: "rgba(255, 255, 255, 1)",
        color: "black !important",
        [theme.breakpoints.up('lg')]: {
            minHeight: "107px",
            padding: "20px !important",
            fontSize: "34px",
        },
        [theme.breakpoints.between('sm', 'md')]: {
            padding: "15px 20px !important",
        },
        [theme.breakpoints.down('xs')]: {
            minHeight: "107px",
            padding: "20px !important",
            fontSize: "34px",
        },
    },
    quesStyleWritten: {
        [theme.breakpoints.up('lg')]:{
            padding: "20px 15px",
            width: "900px",
            minHeight: "auto",
            height: "auto",
            margin: "auto",
            marginBottom: "20px"
        },
        [theme.breakpoints.between('sm', 'md')]: {
            width: "80%",margin: "auto",
        },
        [theme.breakpoints.down('xs')]:{
            padding: "8% 3%",
            width: "90%",
            margin: "10% auto",
            minHeight: "70px",
        },
    },
    optionGroupStyle: {
        [theme.breakpoints.up('lg')]:{ width: "100%"},
        [theme.breakpoints.only('md')]:{ width: "80%", margin: "20px auto"},
        [theme.breakpoints.only('sm')]:{ width: "90%", margin: "20px auto"},
        [theme.breakpoints.down('xs')]:{ width: "100%"},
    },
    outerOptionDiv: {
        [theme.breakpoints.up('lg')]:{ width: "80%", margin: "auto"},
    },
    writtenTitleStyle: {fontSize: "20px"},
    QWI_OptionsDiv: {
        [theme.breakpoints.up('lg')]:{
            flex: "0 0 50%", maxWidth: "50%",
            float: "right",
            width: "85%",
            justifyContent: "center",
            alignItems: "center",
            margin: "auto",
        },
        [theme.breakpoints.down('xs')]:{ width: "80%", margin: "auto"},
        [theme.breakpoints.between('sm','md')]:{ width: "90%", margin: "auto"},
    },
    row: {display: "flex", flexWrap: "wrap"},
    quizBottomVectorStyle:{
        backgroundPosition: "bottom",
        minHeight: "100vh",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        [theme.breakpoints.up('lg')]:{
            backgroundImage: `url(${bottomVector})`,
        },
        [theme.breakpoints.down('xs')]:{
            backgroundImage: `url(${bottomVectorMob})`,
        },
        [theme.breakpoints.between('sm', 'md')]:{
            backgroundImage: `url(${bottomVectorMob})`,
        },
    },
    displayTitleImageStyle: {
        position: "absolute",
        [theme.breakpoints.up('sm')]:{
            right: "10vw",
            height: "70vh",
            width: "80vw",
            display: "flex",
            flexWrap: "wrap",
        },
        [theme.breakpoints.down('sm')]: {
            width: "90%",
            right: "5%",
        },
    },
    outer: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        alignItems: "center",
        borderColor: "#167bff",
        boxShadow: "none",
        margin: "0",
        lineHeight: "1",
        justifyContent: "center",
        height:"auto",
    },
    withImageDiv: {
        [theme.breakpoints.down('md')]:{minHeight:"75vh",},
        [theme.breakpoints.up('lg')]:{minHeight:"70vh",},
        width: "100%", display: "flex", flexWrap: "wrap",
    },
    formControlDiv: {
        [theme.breakpoints.up('lg')]:{
            marginRight: "40px",
            marginLeft: "20px",
            flex: "0 0 41.6666667%",
            maxWidth: "41.6666667%"
        },
        [theme.breakpoints.between('sm','md')]:{
            width: "100%",
            margin: "auto",
            padding: "5%"
        },
        [theme.breakpoints.down('xs')]:{
            width: "90%",
            margin: "auto",
            padding: "5%"
        },
    },
    imageQuesMob: {
        width: "95%",
        padding: "10px",
        fontSize: "20px",
        justifyContent: "center",
        alignItems: "center",
        margin: "auto",
        display: "flex",
        flexWrap: "wrap"
    },
    imageQues: {
        [theme.breakpoints.up('lg')]:{
            marginBottom: "unset",height: "25%",
        },
        [theme.breakpoints.up('sm')]:{
            display: "flex",
            flexWrap: "wrap",
            fontSize: "20px",
            padding: "10px",
            marginBottom: "20px",
            justifyContent: "center",
            alignItems: "center",
        },
        [theme.breakpoints.down('sm')]:{
            display: "block",
            width: "90%",
            height: "90%",
            fontSize: "16px",
            flexGrow: "0",
            backgroundColor: "transparent",
            margin: "10px auto",
        }
    },
}));

export const handleTitleImageStyle = (isPortrait) => {

    const imgStyle = makeStyles(theme => ({
        titleImageStyle: {
            [theme.breakpoints.up('lg')]:{
                height: isPortrait ? "75%" : "300px",
                width: isPortrait ? "75%" : "250px",
                flexGrow: "0",
                backgroundColor: "transparent",
                margin: "auto",
                justifyContent: "center",
                display: "flex",
                flexWrap: "wrap",
            },
            [theme.breakpoints.between('sm','md')]:{
                display: "block",
                height: isPortrait ? "180px" : "150px",
                maxHeight: isPortrait ? "190px" : "160px",
                flexGrow: "0",
                backgroundColor: "transparent",
                margin: "auto",
            },
            [theme.breakpoints.down('xs')]:{
                display: "block",
                width: isPortrait ? "60%" : "90%",
                maxWidth: isPortrait ? "70%" : "90%",
                height: "90%",
                flexGrow: "0",
                backgroundColor: "transparent",
                margin: "auto",
            },
        },
    }));
    return (`${imgStyle().titleImageStyle}`);
}

export const WithImageTheme = createMuiTheme({
    overrides: {
        // Style sheet name ⚛️
        MuiFormControlLabel: {
            // Name of the rule
            label: {
                // Some CSS
                textAlign: "center",
                alignItems: "center",
                margin: "auto"
            },
        },
        MuiFormControl: {
            root: {
                display: "contents",
            }
        },
        MuiFormLabel: {
            root: {
                height: "100%",
            }
        }
    },
});

export const WrittenTheme = createMuiTheme({
    overrides: {
        // Style sheet name ⚛️
        MuiFormControlLabel: {
            // Name of the rule
            label: {
                // Some CSS
                textAlign: "center",
                alignItems: "center",
                margin: "auto"
            },
        },

    },
});

export const WrittenThemeMob = createMuiTheme({
    overrides: {
        // Style sheet name ⚛️
        MuiFormControlLabel: {
            // Name of the rule
            label: {
                // Some CSS
                textAlign: "center",
                alignItems: "center",
                margin: "auto"
            },
        },
        MuiFormControl: {
            root: {
                minWidth: "100%",
            }
        },
    },
});

export const QuestionsLinearProgress = withStyles(() => ({
    root: {
        height: 10,
        borderRadius: 5,
        alignItems: "center",
        margin: "10px auto"
    },
    colorPrimary: {
        backgroundColor: "#c4c4c4",
    },
    bar: {
        borderRadius: 5,
        backgroundColor: '#fdd4a9',
    },
}))(LinearProgress);