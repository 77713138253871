import React, {useContext, useState} from 'react';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FilledInput from "@material-ui/core/FilledInput";
import {TextField, withWidth} from "@material-ui/core";
import RocketAsset from "../../assets/rocket_asset.png";
import ArrowArchAsset from "../../assets/arrow_arch_asset.png";
import Rocket from "../../assets/rocket.png";
import {editDetailsStyles as useStyles} from "../../screens/dashboard/DashboardStyleSheet";
import PropTypes from "prop-types";
import {SCREEN_WIDTHS, widthValues} from "../../utils/common";
import FullCloud from '../../assets/full_cloud.png';
import AuthContext from "../../utils/AuthContext";

const RenderFormAdditionalDetails = ({params}) => {
    const classes = useStyles();
    const isMobile = (params.width === SCREEN_WIDTHS.XS);
    const isTablet = ((params.width === SCREEN_WIDTHS.SM) || (params.width === SCREEN_WIDTHS.MD));
    let auth = useContext(AuthContext);
    let user = auth.user;
    let isReferralEditable = auth.isReferralEditable;
    const [dob, setDob] = useState(user.dob);
    const [refCode, setRefCode] = useState(user.frsrefcode);
    const [firstName, setFirstName] = useState(user.firstname);
    const [lastName, setLastName] = useState(user.lastname);
    const [email, setEmail] = useState(user.email);

    if (isMobile || isTablet)
        return (
            <div className={`${classes.row} ${classes.FormOuterDiv}`}>
                <form autoComplete="off" onSubmit={(event) => params.handleSubmit(event, firstName, lastName, email, dob, refCode)}
                      className={classes.formStyle}>
                    {isTablet && <img src={FullCloud} alt="cloud" className={classes.additionalFormFullCloudStyle}/>}
                    <div className={classes.formOuterStyle}>
                        <div className={classes.infoInputStyle}>
                            <FIRSTNAME firstName={firstName} setFirstName={setFirstName}
                                       filledInputStyle={classes.filledInputStyle}/>
                            <LASTNAME lastName={lastName} setLastName={setLastName}
                                      filledInputStyle={classes.filledInputStyle}/>
                            <EMAILID email={email} setEmail={setEmail}
                                       filledInputStyle={classes.filledInputStyle}/>
                            <DOB dob={dob} setDob={setDob} classes={classes}/>
                            <REFCODE isReferralEditable={isReferralEditable} refCode={refCode} setRefCode={setRefCode}
                                     filledInputStyle={classes.filledInputStyle}/>
                        </div>
                    </div>
                    {isTablet && <img src={FullCloud} alt="cloud" className={classes.additionalFullCloudLeftStyle}/>}
                    <img src={RocketAsset} alt="RocketAsset" className={classes.rocketAssetAdditionalDetailsStyleMob}/>
                    <input className={classes.submitBtnStyle} id={"saveprofile"} type='submit' value="Save"/>
                    <button className={classes.btn_style} onClick={params.logOut}>Logout</button>
                </form>
            </div>
        )
    else
        return (
            <div className={classes.FormOuterDiv}>
                <div className={classes.row}>
                    <div className={classes.formOuterStyle}>
                        <p>
                            Following information will be printed on your FRS certificate, and sent to your email id. Please fill carefully.
                        </p>
                        <form autoComplete="off" onSubmit={(event) => params.handleSubmit(event, firstName, lastName, email, dob, refCode)}>
                            <div className={classes.infoInputStyle}>
                                <FIRSTNAME firstName={firstName} setFirstName={setFirstName}
                                           filledInputStyle={classes.filledInputStyle}/>
                                <LASTNAME lastName={lastName} setLastName={setLastName}
                                           filledInputStyle={classes.filledInputStyle}/>
                                <EMAILID email={email} setEmail={setEmail}
                                         filledInputStyle={classes.filledInputStyle}/>
                                <DOB dob={dob} setDob={setDob} classes={classes}/>
                                <REFCODE isReferralEditable={isReferralEditable} refCode={refCode} setRefCode={setRefCode}
                                         filledInputStyle={classes.filledInputStyle}/>
                                <input className={classes.submitBtnStyle} id={"saveprofile"} type='submit'
                                       value="Save"/>
                            </div>
                        </form>
                    </div>
                    <img src={ArrowArchAsset} alt="arrow arch asset" className={classes.ArrowArchAssetAdditionalStyle}/>
                </div>
                <img src={Rocket} alt="rocket" className={classes.RocketAdditionalStyle}/>
            </div>
        )
}
RenderFormAdditionalDetails.propTypes = {
    width: PropTypes.oneOf(widthValues),
}
export default withWidth()((RenderFormAdditionalDetails));

//EMAIL ID input from the user
const EMAILID = ({email, setEmail, filledInputStyle}) => {
    return (
        <FormControl required fullWidth={true} variant="filled" margin={"normal"} className={filledInputStyle}>
            <InputLabel htmlFor="component-filled">Email Id</InputLabel>
            <FilledInput disableUnderline={true} id="component-filled"
                         className={filledInputStyle} value={email} type={'email'}
                         onChange={(e) => setEmail(e.target.value)}/>
        </FormControl>
    );
}

//DOB input form the user
const DOB = ({dob, setDob, classes}) => {
    return (
        <FormControl required fullWidth={true} variant="filled" margin={"normal"}>
            <TextField label={"Date of Birth"} id="component-filled" type='date' value={dob} required={true}
                       className={`${classes.filledInputStyle} ${classes.filledInputStyleDob}`}
                       InputLabelProps={{shrink: true, className: classes.inputLabelStyle}}
                       InputProps={{disableUnderline: true}}
                       inputProps={{style: {marginLeft: "10px"}, max: getDateYearsAgo(10), min: getDateYearsAgo(18),}}
                       onChange={(e) => setDob(e.target.value)}/>
        </FormControl>
    )
}

//Referral code input from the user
const REFCODE = ({isReferralEditable, refCode, setRefCode, filledInputStyle}) => {
    return (
        <FormControl fullWidth={true} variant="filled" margin={"normal"}>
            <InputLabel htmlFor="component-filled">Referral code</InputLabel>
            <FilledInput disabled={!isReferralEditable} disableUnderline={true} id="component-filled"
                         className={filledInputStyle} value={refCode}
                         onChange={(e) => setRefCode(e.target.value)}/>
        </FormControl>
    );
}

//First Name input from the user
const FIRSTNAME = ({firstName, setFirstName, filledInputStyle}) => {
    return (
        <FormControl fullWidth={true} variant="filled" margin={"normal"}>
            <InputLabel htmlFor="component-filled">First Name</InputLabel>
            <FilledInput id="component-filled" disableUnderline={true}
                         className={filledInputStyle} value={firstName}
                         onChange={(e) => setFirstName(e.target.value)}/>
        </FormControl>
    );
}

//Last Name input from the user
const LASTNAME = ({lastName, setLastName, filledInputStyle}) => {
    return (
        <FormControl fullWidth={true} variant="filled" margin={"normal"}>
            <InputLabel htmlFor="component-filled">Last Name</InputLabel>
            <FilledInput id="component-filled" disableUnderline={true}
                         className={filledInputStyle} value={lastName}
                         onChange={(e) => setLastName(e.target.value)}/>
        </FormControl>
    );
}

//Return the date X years ago
const getDateYearsAgo = (years) => {
    let day = new Date();
    day.setFullYear(day.getFullYear() - years);
    let month = day.getMonth() <= 9 ? '0' + (day.getMonth() + 1) : (day.getMonth() + 1);
    let date = day.getDate() <= 9 ? ("0" + day.getDate()) : day.getDate();
    return day.getFullYear() + '-' + month + '-' + date;
}