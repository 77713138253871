import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import PlayArrowOutlinedIcon from "@material-ui/icons/PlayArrowOutlined";
import {CircularProgress, withWidth} from "@material-ui/core";
import React from "react";
import {useStylesScore, previousAssessmentTableOuterDivStyle} from '../../screens/dashboard/DashboardStyleSheet';
import Button from "@material-ui/core/Button";
import {SCREEN_WIDTHS, widthValues} from "../../utils/common";
import PropTypes from "prop-types";

const PreviousAssessmentsTable = (params) => {

    const classes = params.classes;
    const isMobile=(params.width === SCREEN_WIDTHS.XS);
    const classNames = previousAssessmentTableOuterDivStyle(params.newQuizAllowed);

    function isAttemptExpired(startTime) {
        let currentTime = new Date();
        return (currentTime - startTime) < 8640000;
    }

    return (
        <div className={classNames.outerDiv}>
            <p className={classes.previousAssessmentPara}>Your Previous Assessments</p>
            {/* display the information of previously attempted in table*/}
            <TableContainer className={classes.tableStyle} component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.TableHeadStyle}>Attempted On</TableCell>
                            {isMobile || <TableCell className={classes.TableHeadStyle}>Time Taken (s)</TableCell>}
                            <TableCell className={classes.TableHeadStyle}> Score </TableCell>
                            <TableCell className={classes.TableHeadStyle}>Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {params.userQuizAttempts.map((attempt) => (
                            <TableRow key={attempt.id}>
                                <TableCell component="th" scope="row" className={classes.TableRowStyle}>
                                    {new Date(parseInt(attempt.starttime)).toLocaleString()}
                                </TableCell>
                                {
                                    isMobile ||
                                    <TableCell component="th" scope="row" className={classes.TableRowStyle}>
                                        {attempt.status === 'Draft' ? '-' : (parseInt(attempt.endtime) - parseInt(attempt.starttime)) / 1000}
                                    </TableCell>
                                }
                                <TableCell className={classes.TableRowStyle}>
                                    {
                                        attempt.status === 'Draft' ?
                                            <div className={classes.PlayArrowOutlinedIconOuterDivStyle}>
                                                <span className={classes.PlayArrowOutlinedIconOuterSpanStyle}>
                                                    <PlayArrowOutlinedIcon className={classes.PlayArrowOutlinedIconStyle}/>
                                                </span>
                                                <ScoreCircularProgress value={0}/>
                                            </div>
                                            :
                                            <div className={classes.TableRowStyle}>{Math.round(attempt.totalscore)}/100</div>
                                    }
                                </TableCell>
                                <TableCell className={classes.TableRowStyle}>
                                    {(typeof attempt.questionattempts === "undefined" && isAttemptExpired(attempt.starttime)) ?
                                        <Button className={classes.ResumeBtnStyle} variant="contained" color="primary"
                                                onClick={(e) => params.handleResumeQuiz(attempt, e)}>Resume</Button>
                                        :
                                        attempt.status
                                    }
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}
PreviousAssessmentsTable.propTypes={
    width: PropTypes.oneOf(widthValues),
}
export default withWidth()((PreviousAssessmentsTable));
function ScoreCircularProgress(props) {

    const classes = useStylesScore();

    return (
        <div className={classes.root}>
            <CircularProgress variant="determinate" className={classes.bottom} size={34} thickness={5} {...props} value={100} />
            <CircularProgress variant="determinate" className={classes.top} size={34} value={props.value} thickness={5} {...props}
                              classes={{ circle: classes.circle,}} />
        </div>
    );
}
