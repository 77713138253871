import React, {useEffect} from "react";
import MarksMeter from '../../components/outcome/MarksMeter';
import SkillsBlock from "../../components/outcome/SkillsBlock";
import UserBadgeInfoAndSharing from "../../components/outcome/UserBadgeInfoAndSharing";
import DetailedAnalysis from "../../components/outcome/DetailedAnalysis";
import {loadingScoreStyle as useStyle} from './QuizScoreStyle';
import {SCREEN_WIDTHS, widthValues} from "../../utils/common";
import PropTypes from "prop-types";
import {withWidth} from "@material-ui/core";
import {setAnalyticsScreen} from "../../services/Analytics";
import {Constants} from "../../utils/Constants";

function QuizScore(params) {
    const isMobile = (params.width === SCREEN_WIDTHS.XS);
    const isTablet = ((params.width === SCREEN_WIDTHS.SM) || (params.width === SCREEN_WIDTHS.MD));

    const classes = useStyle();
    let userSubmitOutcome = params.UserSubmitOutcome;
    let score = userSubmitOutcome.totalscore;
    const TotalTimeTakenInMinutes = Math.round((userSubmitOutcome.totaltime) / 60000);

    useEffect(() => {
        setAnalyticsScreen(Constants.screen_result)
    })


    if (isMobile) {
        return <div className={classes.outerDiv}>
            <div className={classes.userSideStyleMobile}>
                {<UserBadgeInfoAndSharing UserBadgeInfo={userSubmitOutcome}/>
                }
            </div>
            <MarksMeter score={score} timeTaken={TotalTimeTakenInMinutes}
                        scorerange={userSubmitOutcome.scorerange}/>
            <SkillsBlock isMobile={isMobile}/>
            <div className={classes.detailedAnalysisOuterDiv}><DetailedAnalysis/></div>
        </div>;
    } else if (isTablet) {
        return (
            <div className={classes.outerDiv}>
                <div className={classes.userSideStyleMobile}>
                    {<UserBadgeInfoAndSharing UserBadgeInfo={userSubmitOutcome}/>
                    }
                </div>
                <div className={classes.skillsAndMarksMeterOuterDiv}>
                    <div className={classes.col}><MarksMeter score={score} timeTaken={TotalTimeTakenInMinutes}
                                                             scorerange={userSubmitOutcome.scorerange}/></div>
                    <div className={classes.col}><SkillsBlock isMobile={isMobile}/>
                    </div>
                </div>
                <div className={classes.detailedAnalysisOuterDiv}><DetailedAnalysis/></div>
            </div>
        )
    }
    return (
        <div className={classes.outerDiv}>
            <div>
                {/* it renders the marks meter and skill sets based on which score is calculated */}
                <div className={`${classes.firstRowStyle} ${classes.firstColStyle}`}>
                    <MarksMeter score={score} timeTaken={TotalTimeTakenInMinutes}
                                scorerange={userSubmitOutcome.scorerange}/>
                    <SkillsBlock isMobile={isMobile}/>
                </div>
                {/* it displays the user badge info if user achieved a badge or no badge message accordingly */}
                <div className={`${classes.userStyle} ${classes.firstColStyle}`}>
                    <div className={`${classes.row} ${classes.userSideStyle}`}>
                        {<UserBadgeInfoAndSharing UserBadgeInfo={userSubmitOutcome}/>
                        }
                    </div>
                </div>
                {/* render the details analysis part*/}
                <div className={`${classes.appDownload} ${classes.firstColStyle}`}><DetailedAnalysis/></div>
            </div>
        </div>
    )

}

QuizScore.propTypes = {
    width: PropTypes.oneOf(widthValues),
}
export default withWidth()((QuizScore));
