import React, {useEffect, useState} from "react";
import {Rating} from "@material-ui/lab";
import {AddUserFeedback} from "../../services/Database";
import {FeedBackStyles, theme} from './styles';
import cloud from './../../assets/full_cloud.png';
import phoneFeedback from './../../assets/phone_feedback.png';
import rocket from './../../assets/rocket.png';
import ArrowArchAsset from "../../assets/arrow_arch_asset.png";
import HalfCloud from "../../assets/AdditionalDetailsRightHalfCloud.png";
import {ThemeProvider} from "@material-ui/core/styles";
import {SCREEN_WIDTHS, widthValues} from "../../utils/common";
import PropTypes from "prop-types";
import {withWidth} from "@material-ui/core";
import {UserFeedbackModel} from "../../models/FRSDataModels";
import {Analytics, setAnalyticsScreen} from "../../services/Analytics";
import {Constants} from "../../utils/Constants";

function UserFeedback(params) {
    const isMobile=(params.width === SCREEN_WIDTHS.XS);
    const isTablet=((params.width === SCREEN_WIDTHS.SM)||(params.width === SCREEN_WIDTHS.MD));

    const classes = FeedBackStyles();
    const [rating, setRating] = useState(4);
    const [ratingText, setRatingText] = useState("");

    useEffect(() => {
        setAnalyticsScreen(Constants.screen_feedback)
    })
    const handleRatingTextChange = (event) => {
        setRatingText(event.target.value);
    }

    //Handle submit of Feedback data
    const handleSubmit = async () => {
        Analytics(Constants.screen_feedback, Constants.track_submit_feedback, Constants.track_click)
        let feedback = new UserFeedbackModel();
        feedback.assessmentid = params.attemptId;
        feedback.rating = rating;
        feedback.ratingText = ratingText;
        await AddUserFeedback(feedback);
        params.handleFeedbackProvided();
    }

    if (isMobile || isTablet){
        return (
            <div className={classes.background}>
                <img src={cloud} alt={"cloud"} className={classes.cloud}/>
                <img src={phoneFeedback} alt={"phone"} className={classes.phone}/>
                <RenderFeedbackForm classes={classes} rating={rating}
                                    ratingText={ratingText} setRating={setRating} setRatingText={setRatingText}
                                    handleRatingTextChange={handleRatingTextChange} handleSubmit={handleSubmit}/>
                <img src={ArrowArchAsset} alt={"ArrowArchAsset"} className={classes.archArrow}/>
                {isTablet && <img src={HalfCloud} alt={"HalfCloud"} className={classes.halfCloud}/>}
            </div>
        )
    }
    else
        return (
            <div className={classes.background}>
                <div className={classes.leftSide}>
                    <img src={cloud} alt={"cloud"} className={classes.cloud}/>
                    <img src={phoneFeedback} alt={"phone"} className={classes.phone}/>
                    <img src={rocket} alt={"rocket"} className={classes.rocket}/>
                </div>
                <ThemeProvider theme={theme}>
                    <RenderFeedbackForm classes={classes} rating={rating}
                                        ratingText={ratingText} setRating={setRating} setRatingText={setRatingText}
                                        handleRatingTextChange={handleRatingTextChange} handleSubmit={handleSubmit}/>
                </ThemeProvider>
                <img src={ArrowArchAsset} alt={"ArrowArchAsset"} className={classes.archArrow}/>
                <img src={HalfCloud} alt={"HalfCloud"} className={classes.halfCloud}/>
            </div>
        )
}
UserFeedback.propTypes={
    width: PropTypes.oneOf(widthValues),
}
export default withWidth()((UserFeedback));

function RenderFeedbackForm(params) {
    return (
        <div className={params.classes.rightSide}>
            <p className={params.classes.rateYourAssessmentText}>Rate Your Assessment!</p>

            <Rating className={params.classes.ratingStyle}
                    name="size-large"
                    value={params.rating}
                    onChange={(event, newValue) => {params.setRating(newValue);}}
                    size={"large"}
            />
            <textarea
                id={"text-for-feedback"}
                className={params.classes.addAnything}
                rows={5}
                autoFocus
                placeholder="Feedback"
                value={params.ratingText}
                onChange={params.handleRatingTextChange}
            />
            <button id={"sendfeedback"} onClick={() => params.handleSubmit(params.ratingText)} className={params.classes.submitBtn}>
                Send
            </button>
        </div>
    )
}