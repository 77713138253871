import React from "react";
import ReactSpeedometer from "react-d3-speedometer";
import TimerIcon from "@material-ui/icons/Timer";
import {marksMeterStyle as useStyles} from "../../screens/outcome/QuizScoreStyle";
import {SCREEN_WIDTHS, widthValues} from "../../utils/common";
import PropTypes from "prop-types";
import {withWidth} from "@material-ui/core";
import chartAvg from "../../assets/carbon_chart-average.png";

function MarksMeter(props) {

    const classes = useStyles();
    const isMobile=(props.width === SCREEN_WIDTHS.XS);

    return (
        <div className={classes.outerDiv}>
            <p className={classes.ScoreStyle}>
                <b className={classes.yourScoreText}>Your future readiness score is</b>
                <b className={classes.scoreStyle}>{props.score}/100</b>
            </p>
            <p className={classes.ScoreStyle}>
                <img src={chartAvg} alt="Full cloud" className={classes.chartAvgStyle}/>
                <b className={classes.yourScoreText}>You are in the <i>{props.scorerange}</i> of all students who have taken FRS</b>
            </p>
            <div className={classes.timeTakenOuterDiv}>
                <TimerIcon/>
                <b className={classes.timeTakenStyle}>Time Taken : {props.timeTaken} minutes</b>
            </div>
            <ReactSpeedometer
                forceRender={true}
                ringWidth={20}
                width={isMobile ? 300 : 270}
                height={isMobile ? 200 : 190}
                minValue={0}
                maxValue={100}
                segments={8}
                value={parseFloat(props.score)}
                startColor={"#ee2222"}
                endColor={"#05ee08"}
                needleTransitionDuration={2000}
                needleColor="orange"
            />
        </div>
    )
};

MarksMeter.propTypes={
    width: PropTypes.oneOf(widthValues),
}
export default withWidth()((React.memo(MarksMeter)));